import {useMap} from "../../../customHooks/useContextMap";


export default function ({width, height}) {
    const map = useMap();

    return <div style={{
        width,
        height,
        backgroundImage: (map.mapUuid)
            ? `url("/api/v2/map/${map.mapUuid}/preview")`: "",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    }}/>;
}
