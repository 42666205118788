export const chinese = {
    abbr: "CN",
    langCode: "zh-CN",
    name: "chinese",
    endonym: "中國",
    yes: "是",
    no: "不",
    auth: {
        errorView: {
            "email is already in use": "邮箱已注册.",
            "username is already in use": "密码已存在.",
            "user not found": "用户未找到",

            tooLongPassword: "密码不可超过二十个字符",
            tooLongUsername: "帐号不超过三十个字符",
            tooShortPassword: "密码不少于六个字符",
            arentYouRobot: "证明您不是机器人",
            invalidEmailAddress: "地址错误",
            passwordsMustMatch: "密码必须匹配",
            required: "务必注明",
            agreementShouldBeApplied: "必须接受协议的条款",
            birthdateTestFailed: "出生日期格式不正确",
        },
        alreadyLoggedIn: "您已经登录",
        authFailed: "验证失败",
        cantResetPassword: "重置密码失败。链接可能无效",
        confirmPassword: "确认密码",
        didYouForgetPassword: "忘记密码",
        incorrectInput: "数据未输出",
        login: "登录",
        loginRequired: "请登录",
        or: "或者",
        password: "密码",
        passwordSuccessfullyChanged: "密码成功更改",
        reset: "重启",
        resetPassMsgSentOnEmail: "重设密码的链接已发送至指定的电子邮件",
        resetPassword: "重设密码",
        restorePass: "恢复密码",
        signin: "进入",
        signingin: "入口",
        signingout: "出口",
        signingup: "注册",
        signup: "注册",
        signupYourself: "请注册!",
        welcomeTo: "欢迎",
        youAreSuccessfullySignedUp: "您已成功注册！ 请通过点击刚刚发送的电子邮件中的链接来确认您的电子邮件",
        conditionAcceptation: "我完全同意条款",
        userAgreement: " 用户协议",
        networkError: "网络错误",
    },
    editProfile: {
        signInBefore: "需要登录才能编辑个人资料",
        profileEditing: "编辑个人资料",
        changePhoto: "更改照片",
        removePhoto: "删除照片",
        fullNamePlaceholder: "姓名",
        usernamePlaceholder: "用户名",
        interestsPlaceholder: "兴趣",
        emailPlaceholder: "电子邮件",
        countryPlaceholder: "国家",
        regionPlaceholder: "地区",
        settlementPlaceholder: "城市",
        languagesPlaceholder: "使用语言",
        birthdatePlaceholder: "出生日期（yyyy-mm-dd）",
        genderPlaceholder: "性别（男/女）",
        saveChanges: "保存更改",
        profileChangedSuccessfully: "个人资料已成功更改",
        confirmEmail: "电子邮件已成功更改。需要通过邮件中的链接进行确认",
    },
    deleteProfile:{
        usernameIsIncorrect: "用户名不正确",
        profileDeletedSuccessfully: "个人资料已成功删除",
        profileDeleting: "删除个人资料",
        explanation: "用户将被永久删除，无法恢复。请输入用户名以确认删除个人资料。",
        delete: "删除",
    },
    buyError: {
        explanation: "要购买产品，需要先登录",
    },
    studio: {
        header: {
            project: {
                survey: "调查",
                surveyWarn: "您已经参与了该调查！",
                project: "项目",
            },
            menu: {
                file: {
                    title: "文件",
                    items: {
                        newMap: "新地图",
                        myMaps: "选择地图", //old translation, update needed
                        usersMaps: "用户地图",
                        send: "发送到服务器",
                        load: "下载",
                        save: "保存",
                        loadBackground: "上传背景",
                        clearBackground: "删除背景",
                        export: "输出",
                        exportTexts: "导出文本",
                        exportAsPNG: "导出为格式",
                        validate: "验证",
                        clear: "清除",
                    }
                },
                edit: {
                    title: "编辑",
                    items: {
                        undo: "取消",
                        redo: "恢复",
                        copy: "复制",
                        paste: "粘贴",
                        delete: "删除",
                        resize: "调整大小",
                        toolbarOn: "隐藏工具栏",
                        toolbarOff: "显示工具栏",
                        liftGridZindexUp: "Показать сетку над картой",
                        liftGridZindexDown: "Показать сетку под картой",
                    }
                },
                help: {
                    title: "帮助"
                },
                onboarding: {
                    title: "教学"
                },
                projects: {
                    title: "项目"
                },
                store: {
                    title: "商城"
                },
                loadMapTitle: "输入文件名:"
            },
            successMessages: {
                mapSaved: "Карта сохранена"
            },
            figureBar: {
                actionHistory: {
                    timeUnit: " 时间单位",
                }
            },
            firstToolBlock: {
                undo: "Отменить последнее действие",
                redo: "Возвратить отменённое действие",
                copy: "Скопировать выделенные фигуры",
                paste: "Вставить выделенные фигуры",
                save: "Сохранить карту на сервер",
                myMaps: "Открыть меню 'Мои карты'",
                export: "Экспортировать в PNG",
                sharing: "Открыть меню управления доступом",
            },
            secondToolBlock: {
                outline: "概述",
                grid: "Сетка",
                center: "В центр холста",
                background: "Фон холста",
                copyStyle: "Скопировать стиль",
                pasteStyle: "Применить стиль",
                layers: "Открыть меню слоев карты",
            },
            quickLaunchBar: {
                copyParams: "复制样式",
            }
        },
        footer: {
            left: {
                weAreIn: "我们在"
            },
            right: {
                editorModeSwitchHint: "编辑器模式"
            }
        },
        chat: {
            inputPlaceholder: "Написать сообщение...",
            main: "Общий",
            tech: "Технический",
        },
        emojiPicker: {
            search: '搜索',
            notfound: '找不到表情符号',
            skintext: '选择默认皮肤',
            categories: {
                search: '搜索结果',
                recent: '经常使用',
                people: '情绪与人',
                nature: '动物与自然',
                foods: '食物与饮料',
                activity: '娱乐类',
                places: '旅行和地点',
                objects: '些项目',
                symbols: '标记迹象',
                flags: '旗',
                custom: '特别的',
            }
        },
        floatingMenu: {
            sliderStandard: "标准的",
            mouse: "鼠标",
            editingMode: "编辑对象",
            name: "名称",
            description: "描述",
            link: "链接到外部资源",
            emoji: "表情",
            dash: "破折号: ",
            dashHint: "虚线轮廓",
            size: "规格: ",
            sizeHint: "点型规格",
            colorHint: "物体颜色",
            fontSize: "字体大小: ",
            flagHint: "定居点旗帜",
            flags: {
                pinkFlagHint: "粉色旗帜提示：我想在这里居住",
                redFlagHint: "红色旗帜提示：我想来这里参观",
                greenFlagHint: "绿色旗帜提示：我在这里居住过",
                blueFlagHint: "蓝色旗帜提示：我来过这里",
                blackFlagHint: "黑色旗帜提示：我不想在这里居住"
            },
            flagCrosses: {
                pinkCrossHint: "取消粉色旗帜：我不想在这里居住",
                redCrossHint: "取消红色旗帜：我不想在这里参观",
                greenCrossHint: "取消绿色旗帜：我没有在这里居住过",
                blueCrossHint: "取消蓝色旗帜：我没有来过这里",
                blackCrossHint: "取消黑色旗帜：我不想在这里居住"
            },
            transparency: "透明度: ",
            transparencyHint: "物体透明度",
            thickness: "厚度外形",
            thicknessHint: "轮廓形状的厚度",
            relatedUsersHint: "Пользователи, связанные с объектом",
            group: "分组 (Shift+G)",
            ungroup: "取消分组 (Shift+U)",
            undefined: "未定义",
            textAreaPlaceholder: "关联;另一个关联;...",
        },
        plConfigurator: {
            thickness: "厚度",
            set: "设置"
        },
        prConfigurator: {
            radius: "半径",
            vertexCount: "顶点数",
            initRotation: "转动",
            set: "接受"
        },
        myMaps: {
            youHaveNoMaps: "您还没有任何已保存的地图",
            myMaps: "我的地图",
            thisMonth: "这个月",
            spaceUsed: "已占用",
            mapsSelected: "突出显示的地图:",
            removeSelectedMaps: "删除",
            months: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月"
            ],
            year: "年",
            allMapsDeleted: "所有地图版本已删除",
            selectedMapsDeleted: "已删除选定的地图",
            deleteConfirmation: "您确定要永久删除选定的地图吗？",
        },
        usersMaps: {
            usersMaps: "用户地图",
            youHaveNoMaps: "您还没有其他用户的地图副本"
        },
        backgroundLoading: {
            title: "加载背景",
            original: "以原始尺寸上传图片",
            custom: "以自定义尺寸上传图片: ",
            height: "高度",
            width: "宽度",
            apply: "上传",
        },
        licenseAgreement: {
            title: "使用协议",
            licenses: "使用创意地图工作室即表示您同意",
            warning: "在本资源中使用的所有矢量图像（*.svg 格式）均为知识产权，其所有权归项目作者所有。未经版权所有者书面许可，不得复制和在其他互联网资源上发布矢量图像。",
            note: "在社交网络和即时通讯应用程序中发布对绘制地图的链接不违反使用条款，并且欢迎这样做。",
            and: "和",
            privacyPolicy: "隐私政策",
            licenseAgreement: "使用协议",
            apply: "同意",
            oAuthText: "通过社交网络在创意地图工作室进行授权即表示您同意",
            licenseAgreementConditions: "用户协议的条件",
        },
        configureProduct: {
            title: "产品配置",
            text: "将产品配置复制到下面的字段中（参数和按选项卡分配）",
            apply: "申请",
        },
        errors: {
            obsoleteMapLoadingFailed: "无法加载过时的地图",
            incorrectMapLoadingFailed: "地图不正确。可能是因为它已过时或原始文件损坏",
            noSpaceLeft: "没有足够的空间",
            deletionMapError: "删除地图时发生错误",
            mapNotFound: "找不到地图",
            tokenIsIncorrect: "令牌不正确",
            mapCannotBeDeleted: "无法删除地图。可能已被删除",
            smthWentWrong: "Что-то пошло не так... Сохраните карту на компьютер и попробуйте отправить позднее",
            accessDenied: "Карта не может быть сохранена. Вероятно, у Вас нет прав на сохранение этой карты. Или карта была удалена автором",
        },
        forbiddenPage: {
            title: "访问被拒绝",
            authExplanation: "您可能需要联系管理员以授予访问权限，",
            unauthExplanation: "匿名用户不能成为项目成员",
            goToStdStudio: "转到普通版工作室",
            goToAuthorization: "授权",
        },
        warnings: {
            cannotBindUserToObject: "Для добавления пользователей необходимо сохранить карту на сервере",
        },
        info: {
            newChanges: "Карта загружена. Сейчас также будут загружены новые изменения пользователей",
        },
        unsupportedDevicePage: {
            title: "设备不支持",
            explanation: "不支持低分辨率设备（例如移动设备），因为该应用程序设计为在计算机上运行。"
        },
        confirmations: {
            deleteMap: "您确定要删除这张地图吗？",
            changesWillLose: "下载开始后，所有未保存的更改都将丢失。您确定吗？"
        },
        stdMapName: "新地图",
        mapFileName: {
            city: "城市",
            gender: "草地",
            age: "年龄",
        },
        onboarding: {
            learnMore: "了解更多",
            manual: "справки",
            withPencil: "用铅笔",
            and: " 和 ",
            withPolylines: "破碎的",
            joyrideLocale: {back: '返回', close: '关闭', last: '完成', next: '下一步', skip: '跳过'},
            baseSteps: {
                welcomeTo: "欢迎来到创意地图工作室！ 可以使用 Enter 切换工具提示。 另外，如果看完教程后您需要查看任何提示，只需按住 Shift+h 并将光标悬停在感兴趣元素旁边的问号上即可.",
                menu: "主菜单允许您下载地图并将其上传到工作室，以及将它们导出为各种格式并将图像设置为画布背景。 您还可以在此处撤消和重做画布上的操作，从画布上复制、粘贴或删除形状。 有关菜单功能的更多信息，请参阅第 3.6 - 3.15 节和 3.17 - 3.19 节",
    			mapName: "在这里您可以重命名地图.",
    			canvas: "这是一块画布。 在这里你可以画画。 如何做到这一点在下面的卡片中。 您还可以在相应的部分了解绘图 ",
                toolbar: "这是工具栏菜单。有关菜单项的详细信息，请参见下面的卡片。您还可以在相应的部分中了解这些信息",
                localization: "在这里可以更改应用程序的本地化设置",
                editorMode: "这是工作室的模式切换器。以下是对每种模式的简要说明",
                editingMode: "默认情况下，设置为编辑地图模式。在此模式下，进行地图的创建过程",
                watchMode: "这是观看模式。在此模式下，可以查看地图的创建过程",
                scaleSlider: "这是比例尺滑块。通过拖动滑块（或在滑块的不同位置点击），可以更改画布的缩放级别。",
                firstToolBlock: "Здесь можно отменить или возвратить отменённые действия на холсте, скопировать и вставить фигуры, загрузить карту, сохранить карту или её растровое изображение, поделиться картой с другими пользователями (для просмотра или совместной работы). Некоторые из этих функций дублируются также в меню 'Правка'.",
                secondToolBlock: "Здесь можно показать или скрыть контуры фигур, открыть меню слоев карты, установить параметры рисования на основе параметров выделенного объекта или применить параметры к выделенным объектам. Также здесь можно установить цвет фона холста и сетку на нем. Последняя кнопка позволяет вернуться в центр холста.",
                secondToolBlockOutline: "С помощью этого переключателя можно включить или выключить отображение контуров многоугольных объектов. Это полезно в тех случаях, когда у объекта сложная форма и неплотная текстура.",
                secondToolBlockLayers: "",
                secondToolBlockCopyStyle: "",
                secondToolBlockPasteStyle: "",
                secondToolBlockBackground: "С помощью этой кнопки можно открыть меню изменения цвета холста",
                secondToolBlockGrid: "С помощью этого переключателя можно включить или выключить отображение сетки в фоне холста",
                secondToolBlockCenter: "С помощью этой кнопки можно переместиться в центр холста",
                rightContainer: "在编辑模式下，工具菜单位于此处。 从该菜单中，您可以选择要在画布上绘制的对象。 它们分为三种类型。 在查看模式下，有一个地图创建历史的图表，点击它还可以加载地图的各种状态.",
                polylines: "第一种是轮廓图。 借助这些图形，您可以标记边界、河流、道路，总而言之，可以忽略其宽度的扩展对象。 可以绘制这样的对象 ",
                polygons: "第二种类型是多边形。使用多边形可以绘制占据大面积的对象。这些对象也可以绘制",
                points: "第三种类型是点形状。点形状可用于标记地图上的小型但重要的对象，例如城市、工业设施。",
                toolMenu: "工具菜单可用于更改工具和已绘制图形的各种参数。",
                toolMenuDnD: "可以通过在顶部抓取元素来拖动工具菜单。",
                toolMenuModes: "这里显示了画布上的模式和群组控制按钮。关于如何管理模式和分组的详细信息，请参见第3.16、3.20-3.22节。",
                toolMenuEditMode: "В этом режиме можно выделять и редактировать фигуры и их группы.",
                toolMenuParameters: "在菜单的此部分，您可以编辑画布上对象的参数以及当前工具的参数。",
                toolMenuGroup: "分组功能允许将多个对象组合到一组中。在进行分组之前，需要选择感兴趣的对象。",
                toolMenuUngroup: "也可以取消分组。要取消分组，也需要选择要取消分组的对象（可以同时选择多个）。需要注意的是，只会取消选中的分组。如果其中包含子分组，则它们将保持原样。",
                toolMenuName: "您可以在此处更改已绘制对象的名称。同时只能更改一个对象的名称。",
                toolMenuDescription: "在这里，您可以更改已绘制对象的描述。同样，一次只能更改一个对象的描述内容。",
                toolMenuLink: "您还可以为地图上的对象添加指向外部资源的链接，以了解更多关于该对象的信息。只需将链接复制到此工具的单一字段中即可。",
                toolMenuEmojis: "地图上的对象不仅可以通过文本描述，还可以使用表情符号进行描述。可以为对象添加最多10个表情符号。如果选择了多个对象，则表情符号将在每个对象上添加/删除。",
                toolMenuDash: "不仅可以用实线绘制轮廓对象，还可以用虚线绘制。在这里，可以更改地图上对象的虚线样式以及当前工具的虚线样式。",
                toolMenuResize: "可以绘制不同大小的点状对象。在这里，可以为当前工具和已绘制的对象设置大小。",
                toolMenuColor: "地图上的对象可以有不同的颜色。在这里，可以设置当前的颜色或重新上色现有的对象。",
                toolMenuTransparency: "每个地图上的对象（以及当前工具）都可以设置一定的透明度。",
                toolMenuThickness: "可以更改轮廓对象（以及当前工具，如果它是轮廓类型）的线条粗细。",
                toolMenuFlag: "可以用标记表示居住地，显示您是否曾经在那里、居住过，以及是否想要去那里或居住在那里。",
                conclusion: "以后，您可以使用帮助来澄清必要的信息。\\n在第一部分中，您可以看到有关工作室的简要信息。\\n第二部分是在工作室工作的详细指南。\\n第三部分介绍如何做一个或另一个基本动作。 例如，下载地图或绘制图形。\\n第四部分提供法律信息."
            },
            hintMode: {
                menuFile: '“文件”菜单允许您下载地图并将其上传到工作室，将其导出为各种格式并将图像加载到地图背景中。 在这里您还可以清除地图并验证对象.',
                menuEdit: '在"编辑"菜单中，您可以撤销和重做在画布上的操作，复制、粘贴或删除画布上的形状。还可以在这里更改画布的大小。',
                menuHelp: '点击"帮助"菜单，可以打开有关在工作室中使用说明的帮助文档。',
                menuOnboarding: '点击"入门"菜单，可以进行一个简短的工作室导览，介绍使用地图时的所有功能，并提供指向相关帮助文档部分的链接。',
                menuStore: '在这里，您可以进入创意地图工作室的商店。您可以购买或免费获取额外的图标、背景、地图模板和工具，用于在工作室中使用。',
                mapName: '在这里，您可以重新命名地图。',
                currentLocale: '在这里，您可以更改应用程序的语言设置。',
                editorModeSwitch: '这是工作室模式切换器。默认情况下，地图编辑模式已启用。在此模式下，您可以创建地图。还有浏览模式，您可以在此模式下查看地图的创建过程。',
                timeSlider: '上方是地图创建历史的时间轴。通过移动红色线条，您可以查看地图在不同时间点的状态。',
                scaleSlider: '这是一个缩放滑块。通过拖动滑块（或单击滑块上的不同位置），您可以更改画布的缩放比例。',
                firstToolBlockUndo: "Эта кнопка позволяет отменить последнее действие на холсте",
                firstToolBlockRedo: "Эта кнопка позволяет возвратить последнее отменённое действие на холсте",
                firstToolBlockCopy: "Эта кнопка позволяет скопировать выделенные объекты на холсте",
                firstToolBlockPaste: "Эта кнопка позволяет вставить скопированные объекты на холст",
                firstToolBlockSave: "Эта кнопка позволяет сохранить карту на сервер",
                firstToolBlockMyMaps: "Эта кнопка позволяет открыть окошко 'Мои карты'",
                firstToolBlockSharing: "Эта кнопка позволяет открыть меню управления доступом",
                firstToolBlockExport: "Эта кнопка позволяет сохранить растровое изображение карты на компьютер",
                secondToolBlockLayers: "Эта кнопка позволяет открыть меню слоев размещения объектов на карте",
                secondToolBlockCopyStyle: "Эта кнопка позволяет скопировать параметры выделенного объекта в параметры рисования по умолчанию",
                secondToolBlockPasteStyle: "Эта кнопка позволяет применить параметры рисования по умолчанию к выделенным объектам",
                secondToolBlockOutline: "С помощью этого переключателя можно включить или выключить отображение контуров многоугольных объектов. Это полезно в тех случаях, когда когда у объекта сложная форма и неплотная текстура.",
                secondToolBlockGrid: "С помощью этого переключателя можно включить или выключить отображение сетки в фоне холста",
                secondToolBlockCenter: "С помощью этой кнопки можно переместиться в центр холста",
                secondToolBlockBackground: "С помощью этой кнопки можно открыть меню изменения цвета холста",
                figureBarPartitionArrowPrev: '使用此箭头可以向后滚动图标分区。',
                figureBarPartitionArrowNext: '使用此箭头可以向前滚动图标分区。',
                figureBarPartitionResizer: '此元素可调整分区的宽度。只需拖动它，将其向左或向右移动。',
                figureBarToolsArrowPrev: '使用这些箭头可以向后滚动分区内的图标。',
                figureBarToolsArrowNext: '使用这些箭头可以向前滚动分区内的图标。',
                figureBarTool: '这是创建图形所用的工具之一。单击后，该工具将被设置为当前工具，并且在画布上进行绘制时将使用该工具。',
                figureBarSubpartition: '这是工具的子分区。单击后将显示特定主题的附加工具菜单（取决于子分区的主题）。',
                baseTransformationalConnector: '这是基础变换连接器。通过拖动它，您可以更改图形的几何形状。',
                directiveTransformationalConnector: '这是指示性变换连接器。通过拖动它，您可以更改路径段的曲率。',
                closingConnector: '这是闭合捕捉连接器。如果将图形绘制完成后与其重合，它将自动闭合。',
                snappingConnector: '这是标准捕捉连接器。通过它，您可以将绘制的图形的点捕捉到现有的连接点上。当需要对象彼此粘合时，这非常有用。',
                multisnappingConnector: '这是多重捕捉连接器。使用它可以在绘制的图形和现有图形之间创建共享边界。',
                toolMenuDnD: '您可以通过在顶部的元素上拖动它来移动工具栏。',
                toolMenuModes: '这里是画布的工作模式和群组控制按钮。',
                toolMenuEditMode: '在此模式下，可以选择和编辑图形及其组。',
                toolMenuParameters: '在此菜单部分中，您可以编辑画布上对象的参数，以及当前工具的参数。',
                toolMenuGroup: '分组功能允许将多个对象组合成一个组。在分组之前，需要选择要分组的对象。',
                toolMenuUngroup: '可以解散组。要解散组，请选择要解散的组（可以选择多个）。请注意，只有所选择的组将被解散。如果组内有子组，则它们将保留。',
                toolMenuName: '在这里，您可以更改绘制对象的名称。同时，只能更改一个对象的名称。',
                toolMenuDescription: '在这里，您可以更改绘制对象的描述。同样，只能同时更改一个对象的描述。',
                toolMenuLink: "对于地图上的对象，您还可以添加指向外部资源的链接，您可以在其中了解有关该对象的更多信息。 只需将链接复制到该工具的唯一字段中即可",
                toolMenuEmojis: "在地图对象上不仅可以使用文本描述，还可以使用表情符号来描述。每个对象可以添加最多10个表情符号。如果选择了多个对象，则表情符号将被添加/删除到每个对象上。",
                toolMenuDash: "轮廓对象不仅可以用实线绘制，还可以用虚线绘制。您可以在此处更改地图上的对象和当前工具的虚线样式。",
                toolMenuResize: "点状对象可以绘制成不同的大小。您可以为当前工具和已绘制的对象设置大小。",
                toolMenuColor: "地图上的对象可以具有不同的颜色。您可以设置当前的颜色或重新着色现有对象。",
                toolMenuTransparency: "地图上的每个对象（以及当前工具）都可以设置为不同程度的透明。",
                toolMenuThickness: "轮廓对象（以及当前工具，如果它是轮廓类型）的厚度可以调整。",
                toolMenuFlag: "可以使用旗帜标记城镇，以显示您是否曾经在该地居住，或者是否希望去游览或居住。",
                mapSharing: "可以在社交媒体、即时通讯应用或Creative Maps Studio中与其他用户分享绘制的地图！只需点击其中一个按钮或指定要与之共享的工作室用户，并点击字段右侧的按钮。如果在社交媒体上分享，可以选择添加自己的消息或保留默认消息。随消息一起发送的是当前地图的图像。还可以只复制当前地图的链接（点击第四个按钮）。"
            }
        },
        sharing: {
            authRequired: "您需要先登录才能分享！",
            sendToServerRequired: "您需要先将地图保存到服务器！",
            noUsersFound: "找不到此用户",
            linkCopiedSuccessfully: "链接已成功复制",
            copyLinkFailed: "无法复制链接",
            sharedSuccessfully: "地图已成功分享！",
            sharingTitle: "Поделиться",
            accessRights: "Права доступа",
            viewing: "Просмотр",
            commenting: "Комментирование",
            editing: "Редактирование",
            shareLink: "Поделиться ссылкой",
            invite: "Пригласить",
            accessConfiguration: "Настройки доступа",
        },
        accessConfiguration: {
            title: "Настройки доступа",
            you: "(Вы)",
            accessViaLink: "Доступ по ссылке",
            remove: "Удалить",
            owner: "Владелец",
        },
        postcard: {
            title: "创建明信片",
            download: "下载明信片",
            albumFormat: "横向格式",
            bookFormat: "纵向格式",
            predefinedColors: "预设颜色",
            slope: "倾斜角度",
            yourText: "您的文本",
            textColor: "文本颜色",
            black: "黑色",
            white: "白色",
            enterText: "输入文本",
            shareOn: "分享至",
            enterPostcardName: "明信片名称：",
            defaultPostcardName: "我的明信片",
            loading: "加载中...",
        },
    },
    store: {
        rub: "р",
        update: "更新",
        publish: "发布",
        unpublish: "隐藏",
        new: "新的!",
        header: {
            searchPlaceholder: "按名称查找",
        },
        footer: {
            description: "用于创意地图工作室的产品目录",
            all: "全部",
            icons: "图标",
            backgrounds: "背景",
            tools: "工具",
            polygons: "多边形",
            templates: "模板",
            contacts: "联系人",
            manual: "帮助",
            confidentiality: "保密性",
            conditions: "使用条件"
        },
        filter: {
            search: "搜索",
            cost: "价格, 卢布",
            costFrom: "从",
            costTo: "至",
            tags: "标签",
            languages: "语言",
            languagesPlaceholder: "中文, 俄语, ...",
            rating: "评分",
            ratingFrom: "从",
            ratingClear: "清除",
        },
        catalog: {
            viewAll: "查看全部",
            catalogTitle: "创意地图商店 - ",
            catalogDescription: "用于创意地图工作室的产品目录",
        },
        product: {
            form: {
                newProduct: "新产品",
                productUpdating: "更新产品",
                loading: "加载中...",
                noOptions: "无选项",
                name: "名称",
                namePlaceholder: "动物，植物等",
                coauthors: "共同作者",
                usersPlaceholder: "ivanivanov@mail.ru，@username",
                tags: "标签",
                tagsPlaceholder: "动物，自然等",
                category: "分类",
                description: "描述",
                mdTitles: "标题",
                mdTitle: "标题",
                productArchive: "产品归档",
                productFile: "产品文件",
                chooseMap: "选择要加载的地图:",
                resolution: "预览分辨率",
                resolutionHint: "请提供大于0但不超过1的数字。1代表原始分辨率，0.5代表下降一半的分辨率，依此类推。在预览时降低质量可以防",
                licenseType: "许可证类型",
                licenses: { //markdown allowed
                    return: "返回",
                    imageLicensesTitle: "Л图像和地图元素以及其他法律文件的许",
                    image: {
                        licenseAgreement: { //this license has no any translations
                            name: "使用条款",
                            text: "<h2>使用条款</h2>" +
                                "<p>本提案为公开要约，定义了有限责任公司“Human Semantics”（TIN 5903143376，OGRN 1195958033457）（以下简称“公司”）的相互权利和义务，由总经理 Belousov Konstantin Igorevich 代表，根据一方面，根据本章程，与接受公开要约（Offer）订立本协议的用户（任何年满18周岁的个人或法人实体），以下统称为“双方”.</p>" +
                                "<p>公开要约是指根据要约中提议的条款向不定数量的人签订合同的要约。 接受要约意味着同意其所有条款。 本要约的接受按照协议第 3 条规定的方式进行。</p>" +
                                "<p>通过接受此要约，您确认您已阅读、理解并完全同意受本协议、根据本协议中规定的条款签订的合同以及公司的隐私政策的约束。 您特此确认，从在本网站注册的那一刻起以及在使用本网站的服务以及个性化服务期间，您是本网站的用户，直到您删除您的帐户和/或直到您亲自联系该用户支持服务放弃与公司的任何关系。</p>" +
                                "<h4>1. 概念定义</h4><ul>" +
                                    "<li>В 在本协议文本中，下列术语具有以下含义：</li>" +
                                    "<li><b>协议</b>是本用户协议，在提供服务的整个期间以及用户访问网站的个性化服务期间管辖公司与用户之间的关系。</li>" +
                                   "<li><b>“网站”</b>是位于互联网 https://creativemaps.studio/ 上的资源，属于公司的财产。</li>" +
                                     "<li><b>“用户”、“您”、“您的”、“您的”、“您的”</b>或任何其他派生词（取决于上下文）是指 (1) 使用本网站的人和/或获得对其内容的访问权限；以及 (2) 同意遵守本协议文本中规定的网站使用规则。</li>" +
                                     "<li><b>“公司”、“我们”、“我们”、“我们”</b>或任何其他类似衍生词（取决于上下文）是指以下实体：Human Semantics Limited Liability Company“（TIN 5903143376，OGRN 1195958033457)，地点地址：彼尔姆地区，彼尔姆，帕克维大道，45G，234号公寓，该网站的所有者。</li>" +
                                     "<li><b>注册</b>是将用户的个人数据输入网站上的特殊表格的程序，这是公司提供服务以及公司履行注册用户所依据的协议所必需的。受益人，以及用户访问网站的个性化服务注册后，用户可以访问其帐户 - 网站上的个人帐户（帐户）。</li>" +
                                     "<li><b>授权</b> - 通过在授权字段中输入个人登录名和密码来识别网站上的用户。</li>" +
                                     "<li><b>“网站内容”</b>是指公司和/或第三方（经公司许可）在网站上发布的所有对象，包括设计元素、文本、图形、插图、照片、图形、视频、程序、音乐、声音、信息、通知和任何其他类似目的的对象、它们的选择或组合。在网站上明确指出的情况下，可以根据以下条款获得使用网站内容的权利：简单的非独占许可，其条款包含在本协议文本中。网站内容的使用权的获取是根据网站产品目录中标明的资费免费或付费进行的在 Creative Card Studio 中使用。</li>" +
                                     "<li><b>“网站软件”</b>是指公司（和/或代表公司的第三方）为本网站开发的软件，包括但不限于所有软件、脚本、代码（HTML代码）、程序等</li>" +
                                     "<li><b>“服务”</b>是指公司为履行协议条款而与用户相关的一系列活动。</li>" +
                                     "<li><b>协议</b> - 公司与用户之间关于用户获取网站内容（材料）的权利的关系，根据条款在网站上标明了获取该网站内容（材料）的权利的可能性本协议的内容。</li>" +
                                     "<li><b>订单</b>是用户从公司产品目录中购买网站内容（材料）的申请，由用户通过在网站上填写特殊表格来完成。</li>" +
                                     "<li><b>“用户内容”</b>是指 (1) 用户在网站上发布的所有出版物，包括但不限于：评论、评级、评论、报告、反馈、发布的设计元素、图形图像、插图、文本、视频、照片、音乐和其他媒体文件、点赞、评级和/或用户在网站上可用的任何其他形式的活动，以及 (2) 用户创建的任何其他内容。用户可以执行此操作，以便根据网站上以下部分中发布的相关许可条款免费和付费向其他用户提供对其的访问：https://creativemaps.studio/store/licenses /列表。</li>" +
                                     "<li>用户为了提供付费访问而发布用户内容的行为受本协议第 5.12-5.16 条的监管。</li>" +
                                     "<li><b>“材料”</b>是指矢量图形图像、光栅背景以及用于在网站上的矢量图形编辑器中创建地图的网站内容的其他元素。</li>" +
                                     "<li><b>隐私政策</b> - 用户协议中规定公司处理用户个人数据的义务的条款。</li>" +
                                     "<li><b>个人数据</b> - 用户在注册和/或下订单时自愿且知情地提供的个人信息（包括姓氏、名字、父名、联系电话号码、电子邮件地址）网站，并且公司履行以注册用户为受益人的协议以及用户访问网站的个性化服务所必需的。</li></ul>" +
                                "<h4>2.一般规定</h4><ol>" +
                                   "<li>公司根据本协议中规定的条款和条件向用户提供其服务和网站服务。</li>" +
                                     "<br/><p>网站服务的使用以及用户与公司之间与使用网站服务相关的所有关系均受本协议以及公司与公司之间签订的其他合同/协议的管辖。用户。</p><br/>" +
                                     "<li>通过使用本网站的任何服务或利用其任何功能，您表示您无条件同意本协议的所有条款以及本网站页面上规定的所有其他条件，您承诺遵守这些条件，如果不同意任何条件，请立即停止使用服务并离开网站。</li>" +
                                     "<li>您被告知，本协议适用于本网站当前所有现有服务，以及任何新服务的开发和/或添加。</li>" +
                                     "<li>本协议对其双方（即公司和用户）具有约束力。用户只有在收到公司事先书面同意后才能转让其在本协议下的权利。</李>" +
                                     "<li>您同意，有关本协议以及您与公司之间的关系的所有可能的争议将根据俄罗斯法律解决。</li>" +
                                     "<li>您同意法院承认本协议任何条款无效或不可执行并不意味着本协议其他条款无效或不可执行。</li>" +
                                     "<li>公司在您违反本协议规定的情况下不采取行动，并不剥夺公司日后采取适当行动保护其利益的权利，也不意味着公司放弃如果随后发生类似或相似的违法行为，其权利。</li> " +
                                     "<li>公司保留更改本协议的权利，恕不另行通知。新版本的协议自在网站上发布之日起生效：https://creativemaps.studio/store/licenses/许可证协议。当前版本 该协议始终位于此页面：https://creativemaps.studio/store/licenses/licenseAgreement。</li>" +
                                     "<li>您可以在注册期间在协议文本链接旁边的特殊字段中放置同意标记（复选标记），并在“我完全接受本协议的条款”这一短语旁边，以确认您同意本协议的条款。用户协议”和/或单击短语下的“我接受”按钮，表明您在通过社交网络授权和/或在本网站上工作时接受本协议，而无需在本网站上注册和/或执行其他操作。 </li>" +
                                     "<li>公司建议 18 岁以下人士不要访问本网站的个性化服务。公司不会故意收集未成年人的信息，包括他们的个人数据，并且不会使用或针对本网站的内容未成年人。未成年人的行为（包括他们在网站上购买服务）的责任由未成年人的法定代表人承担。</li></ol>" +
                                "<h4>3.网站用户</h4><ol>" +
                                     "<p>一般标准</p><br/>" +
                                    "<li>要使用本网站，用户必须满足以下条件（累计）：<ol>" +
                                         "<li>年满 18 岁，并且</li>" +
                                         "<li>根据已生效的法院判决，或在现行法律或本协议条款规定的情况下，访问本网站的权利不受限制。</li></ol ></li>" +
                                     "<li>法人实体通过法人代表在网站上注册即可使用本网站。</li>" +
                                     "<br/><p>在网站上注册并创建个人帐户</p><br/>" +
                                     "<li>在网站上注册后，您将获得使用帐户的不可转让权利。创建帐户对于访问网站的个性化服务以及进行购买（获得使用网站内容的权利（材料）根据协议条款）。</li>" +
                                     "<li>您对使用帐户的登录名和密码的安全性以及使用您帐户下的公司个性化服务进行的所有操作负责。</li>" +
                                     "<li>您同意，只有您在使用本网站的个性化服务的每次会话结束时，才能独立安全地关闭您的帐户（“退出”按钮），并承诺确保您使用的登录名和密码的机密性您的帐户。</li>" +
                                     "<li>您确认您无权将您的账户使用登录名和密码转让给第三方。您也同意本公司对您与第三方使用本公司个性化服务进行的交易不承担任何责任您帐户下的网站。</li>" +
                                     "<li>您同意，如果您在网站上的帐户出现任何安全漏洞，您必须立即通知公司。</li>" +
                                     "<li>您同意，如果您在足够长的时间内没有使用本网站的个性化服务，公司保留删除您帐户的权利。在每种特定情况下，确定帐户不活动的充分性仍由我们自行决定公司。</li>" +
                                     "<li>网站服务可能包含指向其他资源的链接。您承认并同意，公司对这些资源及其内容的可用性以及与您使用网站内容相关的任何后果不承担任何责任。这些资源。</li>" +
                                     "<li>您还同意，如果您从公司网站访问第三方资源和/或其他第三方，公司对您提供给第三方资源和/或其他第三方的个人数据不承担任何责任。</li>" +
                                     "<li>您确认，对于因您违反本协议的规定以及不当访问和/或使用本网站的个性化服务而可能发生的数据丢失和/或损坏，公司不承担任何责任.</li>" +
                                     "<br/><p>用户注册时的义务</p><br/>" +
                                     "<li>要购买网站上的内容以及访问网站的个性化服务，您同意就注册过程中提出的问题提供准确、完整的信息，并且是履行公司相关义务所必需的信息您购买的网站内容和/或您对网站个性化服务的访问权限。您还承诺及时更新此信息。</li>" +
                                     "<li>如果您提供的信息不正确，或者本公司有充分理由相信您提供的信息不完整和/或不可靠，本公司有权封锁或删除您的帐户并拒绝您使用其服务。< /li >" +
                                     "<br/><p>终止用户注册</p><br/>" +
                                    "<li>您同意，如果您违反本协议的任何规定，公司保留阻止和/或删除您的帐户，或终止您与本网站任何服务相关的帐户的权利。</li>" +
                                     "<li>您有权随时要求公司删除和/或阻止您在网站上的帐户。为此，您必须通过 support@creativemaps.studio 向用户支持服务发送相应的请求。< /li></ol> " +
                                "<h4>4.知识产权</h4><ol>" +
                                     "<li>网站软件和网站内容按照俄罗斯联邦现行民法以及知识产权保护领域的国际条约和公约的规定受版权保护。</li>" +
                                     "<li>禁止用户复制、复制、修改、编译、分发、以任何形式展示、出版、下载、传输、出售或以其他方式分发或使用网站内容和网站软件，除非本条款明确允许此类活动公司与用户之间的协议、合同和协议或俄罗斯联邦现行立法。</li>" +
                                     "<li>本协议文本中的任何内容均不得解释为向用户转让网站内容（全部或单独部分）和/或网站软件的任何专有权，但转让网站内容的权利除外。符合本协议第 8 条的材料。</li></ol>" +
                                "<h4>5. 如何使用本网站</h4><ol>" +
                                     "<p>网站行为规则<p><br/>" +
                                         "<li>使用本网站时，用户同意遵守以下规则：<ol>" +
                                         "<li>遵守用户因加入本协议而承担的所有义务；并且</li>" +
                                         "<li>在网站注册和创建帐户（个人帐户（帐户））时提供有关您自己的准确信息；以及</li>" +
                                         "<li>不冒充任何其他人，包括但不限于不提交第三方的任何数据（未经其明确、事先和知情同意）在网站上注册；以及</li>" +
                                         "<li>通知公司有关帐户的登录名、密码或任何其他用户访问密钥被盗的情况；以及</li>" +
                                         "<li>请勿向第三方提供您的帐户和/或登录名、密码或其他访问密钥的访问权限；以及</li>" +
                                         "<li>不得上传、存储、出版、分发、张贴、做广告、发送、提供或以其他方式使用 (a) 具有威胁性、诽谤性、侮辱性、诽谤性或尊严或商业声誉或侵犯他人隐私的用户内容用户或第三方；并且 (b) 是垃圾邮件、欺凌、粗俗或淫秽内容，包含色情图像和文本、性场景或暴力场景；并且 (c) 包含任何形式的煽动自杀和/或宣扬内容或助长煽动种族、宗教、民族仇恨或敌意，宣扬法西斯主义或种族优越意识形态，或包含极端主义材料；以及 (d) 宣扬侵犯其他用户或第三方的权利或合法利益，或宣扬犯罪或包含实施建议/指南/说明；以及 (e) 违反本协议的其他规则或适用法律禁止的； 和</li>" +
                                         "<li>不执行任何旨在收集其他用户个人数据的行为（使用或不使用自动化工具）；并且</li>" +
                                         "<li>不采取任何行动或协助第三方采取旨在破坏本网站运营的行动，包括但不限于 (a) 上传病毒或恶意代码；(b) 采取可能导致网站瘫痪的行动网站，扰乱网站或网站软件的正常运行，或破坏网站和/或网站内容的外观；</li>" +
                                        "<li>н不采取任何非法、欺诈、歧视或误导性行为.</li></ol></li>" +
                                    "<br/><p>Права Пользователей на размещенный Контент</p><br/>" +
                                    "<li>只有注册用户才有权创建用户内容。</li>" +
                                    "<li>用户创建的用户内容是知识产权的客体，并受现行法律的保护，因此公司不会声称接收且不要求您向其提供对您的用户内容的任何所有权。没有任何内容本协议文本中的“内容”应解释为剥夺或限制用户对其创建的用户内容的权利。</li>" +
                                    "<li>同时，用户就其在网站上发布的所有用户内容向公司提供非排他性、永久、免版税的全球许可（以下简称许可）有权转让和颁发存储、使用、分发、修改、处理、运行、复制、公开使用或展示、翻译您的用户内容以及基于其创建衍生作品的从属许可。</li>" +
                                    "<li>用户颁发的许可证在删除用户帐户和用户内容后仍然有效。</li>" +
                                    "<br/><p>Требования к пользовательскому контенту</p><br/>" +
                                   "<li>禁止用户上传任何可能属于第三方的用户内容或未充分授予该用户使用权的任何用户内容。用户特此承诺赔偿公司因此而产生的所有损失和法律费用。与第三方因发布的用户内容侵犯第三方的知识产权而提起的索赔有关。</li>" +
                                    "<li>公司不会也无法验证用户作为其创建的用户内容的一部分发布的所有材料，因此公司不对此类用户内容的内容、其使用和/或其对第三方的影响负责各方或其他用户。运营本网站并不意味着公司认可、认可、保证、分发和/或相信用户内容中发布的信息。用户负责保护自己以及保护其设备免受病毒侵害和其他恶意软件。对于因使用本网站、其服务和/或用户内容（包括下载）而造成的损害，公司不承担任何责任。</li>" +
                                    "<li>公司有权随时检查用户内容是否符合本协议或现行法律的要求。但是，本协议文本中的任何内容均不应解释为公司有采取任何行动的直接义务。根据其他用户或第三方的要求，对用户内容进行独立验证。</li>" +
                                    "<li>如果您遇到违反本协议条款或侵犯用户或第三方合法权益的用户内容，您可以将投诉发送至电子邮件地址：claim@creativemaps.studio</li>" +
                                     "<li>如果发现用户内容违反本协议条款或适用法律的规定，公司有权随时自行决定，无需警告用户或承担任何责任将来，完全删除此类用户内容，如果屡次违规，请删除发布此类用户内容的用户的帐户。</li>" +
                                     "<br/><p>发布用户内容以提供付费访问</p><br/>" +
                                     "<li>用户有权在网站上发布用户内容 - 矢量图形图像、其他内容（材料），以便其他用户付费访问这些内容。</li>" +
                                     "<li> 用户为了向其他用户付费授予权利而在网站上发布材料，只有在获得公司对此类发布以及发布期间的额外同意后才可能进行以及将所发布材料的权利转让给其他用户的付款金额。</li>" +
                                     "<br/><p>公司的此类额外同意的表达是通过从邮寄地址 noreplay@creativemaps.studio 向用户的电子邮件地址发送一封信来进行的，其中包含对复制产品的明确同意。公司有权拒绝用户批准将材料放置在网站上，以便向其他用户提供付费访问权限，而无需做出任何解释。</p><br/>" +
                                     "<li>公司为提供付费访问而发布用户内容（材料）的报酬为已售用户材料成本的 20%。</li>" +
                                     "<li>公司在收到用户付款请求之日起 10（十）个日历日内，将资金转移给其他用户，以获取出售给其他用户的材料的权利（减去应付给公司的金额），但不得超过每月不得超过一次。转账 公司根据用户在资金支付请求中指定的银行详细信息进行资金转账。</li>" +
                                     "<li>公司会在每个报告期（日历月）结束后的五 (五) 个日历日内，向用户发送一份有关用户拥有的已售材料的报告到用户的个人帐户。</li>" +
                                     "<br/><p>对网站工作的反馈</p><br/>" +
                                    "<li>每个用户不时都有权利（但没有义务）留下或发送其旨在改进网站运营或所提供服务质量的想法、反馈、建议或项目。此类反馈可以由用户通过以下方式发送：feedback@creativemaps .studio</li>" +
                                     "<li>如果提交此类想法、反馈、提案或项目，用户将自动授予公司非排他性、免版税的全球许可，并有权颁发用于存储、使用、分发、修改、发布、复制、公开表演或展示、翻译或创作想法、反馈、建议或项目的衍生作品。</li></ol>" +
                                 "<h4>6. 网站上的广告</h4><ol>" +
                                     "<p>公司的广告</p><br/>" +
                                     "<li>公司有权不时在网站上发布任何广告或营销材料。</li>" +
                                     "<li>用户可能会不时收到公司发送的带有某些广告或营销材料的消息。公司发送指定材料仅在用户同意（以相应订阅的形式表示）的情况下进行。通过执行以下操作：在编辑帐户的窗口中选中“我想通过您的电子邮件接收来自公司的新闻通讯”旁边的框，然后保存更改。</li>" +
                                     "<li>用户有权随时通过执行以下操作拒绝此类免费订阅，无需说明任何理由：取消选中帐户中“我希望通过我的电子邮件接收公司的新闻通讯”旁边的框编辑窗口，然后保存更改。</li>" +
                                     "<br/><p>第三方广告</p><br/>" +
                                     "<li>网站内容可能包含第三方网站的链接和/或有关此类第三方提供的商品/服务的广告或营销材料（以下简称第三方广告）。公司不承担任何责任(1) 第三方广告的内容，以及此类广告中宣传的商品/服务的可用性、质量和安全性；以及 (2) 作为用户而产生或造成的任何损失、损失或损害。此类广告的结果是他使用第三方商品/服务的促销广告</li>" +
                                     "<li>如果通过网站上发布的第三方广告转移到另一个网站，公司无法保证该网站对于用户和/或其计算机来说是安全的。本协议文本中的任何内容均不应被修改。解释为鼓励、推荐或诱导用户使用第三方广告、访问任何第三方网站以及尝试、购买或使用任何第三方产品/服务的表示。</li>" +
                                     "<br/><p>用户投放的广告</p><br/>" +
                                   "<li>用户有权放置与本网站概念密切相关的自己的广告或营销材料。协调用户放置此类广告或营销材料，用户放置此类材料的条件，包括放置广告和营销材料的费用或免费放置材料的协议，由用户和公司额外进行。</li>" +
                                     "<li>用户投放的广告必须符合以下要求：(1) 不得宣传根据现行法律认定为非法的商品/服务，包括但不限于：毒品、卖淫、武器、烟草制品（及相关产品）等，并且（2）不得发布直接或间接针对基于宗教、种族、民族、国籍、性别、残疾、健康状况、怀孕、任何遗传特征的歧视的广告和营销材料、性取向；(3) 不向成年人（18 岁或以上）宣传产品或服务；(4) 不侵犯第三方的合法权益（例如知识产权）；以及 (5 ) 不得发布可能令用户震惊或恐惧的广告或营销材料（例如谋杀现场、尸体、事故的照片）；以及 (6) 不得发布虚假、不准确、不完整或反驳的信息； (7) 不得宣传宣传或促进欺诈或欺骗活动的产品/服务； (8) 不得宣传任何低质量或淫秽内容或含有脏话的内容； (9) 不得宣传其他可能侵犯第三方合法权益、损害本网站声誉、或使本公司受到任何制裁或责任的内容。</li></ol>" +
                                "<h4>7. 站点服务</h4><ol>" +
                                     "<li>通过使用本网站，用户可以访问矢量图形编辑器，该编辑器允许用户创建、编辑和保存矢量图形图像（地图）以及所有图形图像、其他元素创建此类图形图像（地图）所需的网站内容（以下简称“材料”）。</li>" +
                                     "<li>除了本协议第 7.1 条规定的服务外，用户还可以访问以下服务：<ul>" +
                                     "<li>在网站上放置广告；</li>" +
                                     "<li>在网站上放置材料（包括矢量图形图像），以便付费向其他用户授予这些材料的权利；</li>" +
                                     "<li>根据用户的个人订单（技术规范）创建矢量图形图像和其他材料。用户将技术规范发送给公司，以按以下顺序开发此类图形图像和其他内容：发送信件至电子邮件地址 order@creativemaps.studio。单个订单上的图形图像和其他内容的开发由公司根据合同条款、建议（报价）以有偿方式进行。结论发布在网站页面 https://creativemaps.studio/store/licenses/licenseAgreement 上。</li></ul></li>" +
                                     "<li>除了列出的服务之外，公司还提供对将来可能在网站上创建和/或发布的其他服务的访问。</li>" +
                                     "<li>公司免费或收费（收费）提供服务。除非网站上另有明确说明，否则服务均视为免费提供。</li>" +
                                     "<li>付费服务按照网站上注明的费率预付款提供。</li></ol>" +
                                "<h4>8. 材料权利的转让</h4><ol>" +
                                     "<li>网站上发布的材料（矢量图​​形图像、其他内容）是受保护的智力活动成果（知识产权对象）。</li>" +
                                     "<li>就材料而言，公司与用户之间签订了许可协议，由公司（许可方）向用户（被许可方）提供使用材料的权利（以下简称“许可协议”）协议）。</li>" +
                                     "<li>用户被授予使用材料的权利，同时保留公司向其他人颁发许可证的权利（简单（非独占）许可证）。</li>" +
                                     "<li>就材料而言，公司拥有适当的权限，授予用户以下权利：<ol>" +
                                         "<li>有权使用网站上的材料，通过使用网站上发布的矢量图形编辑器来创建图形图像（地图）；</li>" +
                                       "<li>有权无限次下载在其基础上创建的材料和地图；</li>" +
                                         "<li>有权在用户拥有的多个设备的内存中放置和存储根据其创建的材料和地图记录；</li>" +
                                         "<li>有权无限次查看在其基础上创建的材料和地图；</li>" +
                                         "<li>有权修改根据材料创建的地图，并将这些地图用于任何目的，前提是提供原始地图的链接；</li>" +
                                         "<li>有权发布、分发（包括通过社交网络和即时通讯工具）以及以其他方式提供使用材料创建（绘制）的地图的链接；</li>" +
                                         "<li>有权发布（包括通过使用互联网）使用材料创建（绘制）的地图的图像，但必须强制表明此类地图的创建是通过使用本网站进行的。此类指示是通过直接在图像上或图像附近张贴以下文字来进行：“在 Creative Maps Studio 中创建。”</li></ol></li>" +
                                     "<li>所授予的指定权利不受使用期限的限制。</li>" +
                                     "<li>矢量图形以 *.svg 格式传输以供使用。</li>" +
                                     "<li>禁止用户：<ol>" +
                                         "<li>在第三方资源上复制和发布材料；</li>" +
                                         "<li>发布（包括通过使用互联网）使用材料创建（绘制）的地图的图像，但没有表明此类地图的创建是通过使用网站进行的；</li>" +
                                         "<li>将下载的材料转换为其他格式；</li>" +
                                         "<li>重新设计、修改或以其他方式返工材料或创建衍生作品；</li>" +
                                         "<li>使用修改后的地图而不提供原始地图的链接；</li>" +
                                         "<li>对材料和地图进行本协议条款（本协议第 8.4.1 – 8.4.7 条）未明确规定和/或违反法律的任何其他行为。< /li></ol></li>" +
                                     "<li>如果用户违反这些禁令或以本协议中未列出的方式使用材料，公司有权单方面终止与用户签订的材料使用权协议，终止用户访问该材料的权限。使用本公司的材料和所有其他图形图像，并向法院提起诉讼以保护您的权利并赔偿由此造成的损失。</li>" +
                                     "<li>除非网站另有说明，否则材料使用协议（许可协议）的签订是免费的（不收取任何费用）。</li>" +
                                     "<li>对于在网站上的商品商店中发布的材料，材料使用协议的签订是在可偿还的基础上（收费）进行的。</li>" +
                                     "<li>使用材料的报酬金额在网站上的产品商店中标明。</li>" +
                                     "<li>公司在收到材料付款确认书后 24 小时内提供有偿使用材料的权利。付款程序受购买材料的付款说明，发布在页面 https://creativemaps.studio/store/manual</li>" +
                                     "<li>为了获得使用网站商品商店中发布的材料的权利，用户通过在网站上填写特殊电子表格来创建订单。</li>" +
                                     "<li>用户有权取消未付款和已付款的订单，直到公司向他提供已购买材料的访问权限。如果取消订单，已付款订单的资金将在以下期限内退还给用户：自取消订单之日起 10（十）个日历日。退款将按照用户付款时使用的方式进行。在提供对所购买材料的访问权限后，取消订单并将已支付的资金退还给用户不可能。</li></ol>" +
                                 "<h4>9. 隐私政策</h4><ol>" +
                                     "<li>隐私政策的条款以及用户与公司之间有关个人数据处理的关系受俄罗斯联邦 2006 年 7 月 27 日第 152-FZ 号联邦法“关于个人数据”的管辖。 .”</li>" +
                                     "<li>隐私政策适用于公司在用户注册和/或在网站上下订单和/或用户访问网站其他服务期间已收到或可能收到的个人数据。</li >" +
                                     "<li>用户同意，使用本网站的服务即表示无条件同意隐私政策以及其中规定的处理用户个人数据的条件。如果不同意隐私政策的任何条件，用户必须立即停止使用使用服务并离开网站。</li>" +
                                     "<li>公司收集和处理的用户个人数据：<ol>" +
                                         "<li>用户在注册和/或在网站上下订单期间自愿且知情地提供的有关其自身的强制性个人数据；</li>" +
                                         "<li>用户在网站注册或随后使用任何服务时自愿且知情地自行决定提供的可选个人数据，例如用户的联系电话号码、年龄、性别、社会地位等网站的个性化服务；</li>" +
                                         "<li>当用户使用用户设备上安装的软件访问本网站的任何页面时，公司自动接收的匿名数据：IP 地址；来自 Cookie 的信息；有关用户访问本网站的设备的信息（设备类型、型号、浏览器版本）；有关浏览器（或用于访问网站服务的其他程序）的信息；访问时间、请求页面的地址。</li></ol></li>" +
                                     "<li>公司不会验证用户提供的个人数据的准确性，也不会监控其相关性。但是，公司假设用户就注册表中提出的问题提供了可靠且充分的个人数据，并保留该信息是最新的。所有用户应对提供虚假或无效个人数据的后果承担个人责任。</li>" +
                                     "<li>公司仅收集和处理用户使用网站个性化服务和/或用户从网站目录购买材料所需的个人数据，即：<ol>" +
                                         "<li>接受并处理用户订单；</li>" +
                                         "<li>处理并接收用户的付款；</li>" +
                                         "<li>提高网站及其服务的质量；</li>" +
                                         "<li>为用户提供有效的客户支持；</li>" +
                                         "<li>向用户提供网站的个性化服务；</li>" +
                                         "<li>通过接收您对当前网站服务的反馈，改进网站服务的运营、易用性以及开发新服务；</li>" +
                                        "<li>告知用户公司举办的活动和促销活动；</li>" +
                                         "<li>基于匿名数据进行统计和其他研究。</li></ol></li>" +
                                     "<li>对于用户的个人数据，我们会对其保密，除非用户自愿提供有关其自身的信息以供无限数量的人员访问。</li>" +
                                     "<li>公司按照此类信息的保护要求保护用户的个人数据，并有责任采用安全的方法保护此类信息。</li>" +
                                     "<li>为了保护用户的个人数据，确保其正确使用并防止未经授权和/或意外访问，公司采取必要且充分的技术和管理措施。用户提供的个人数据存储在有限的服务器上。访问位于安全场所。</li>" +
                                     "<li>在以下情况下，公司有权将用户的个人数据转移给第三方：<ol>" +
                                         "<li>用户已明确同意此类行为；</li>" +
                                         "<li>为了用户使用本网站的某些服务或为了遵守用户作为受益人的协议，有必要进行传输。在这种情况下，公司在第三方同意的情况下传输用户的个人数据。保守秘密；</li>" +
                                         "<li>俄罗斯立法在法律规定的程序框架内规定了转让。</li></ol></li>" +
                                     "<li>用户有权随时使用个人资料编辑功能更改（更新、补充）其提供的个人资料。</li>" +
                                     "<li>用户可以随时联系用户支持人员，请求删除其提供的个人数据：support@creativemaps.studio。</li>" +
                                     "<li>用户在注册期间在文本链接旁边的特殊字段中放置同意标记（复选标记），以确认其完全自愿同意公司根据隐私政策的条款处理其个人数据在“我完全接受用户协议的条款”短语对面的协议中和/或通过单击该短语下的“我接受”按钮，用户在通过社交网络登录时接受本协议，并且/或在网站上工作而无需在网站上注册和/或执行其他操作。</li>" +
                                     "<li>用户对个人数据处理的同意在用户使用网站服务期间有效。</li>" +
                                     "<li>公司通知用户，网站上记录网站用户的 cookie 是为了分析用户在网站上的行为。使用 cookie 收集的数据是匿名的，无法识别个人身份。</li></li></li></li></li></li></li></li></li></li></li> ol>" +
                                "<h4>10.关于确认同意</h4><ol>" +
                                     "<li>您特此确认您已阅读、理解并完全同意用户协议的所有使用条款，包括协议条款（提供材料使用权的许可协议）。</li >" +
                                     "<li>您有权通过用户支持服务 support@creativemaps.studio 解决有关您与公司之间互动的任何问题。</li>" +
                                     "<li>如果您不接受本协议的任何条款，您有权拒绝确认本协议。</li>" +
                                     "<li>您承认接受本协议意味着您无一例外地完全同意其所有条款和条件。</li></ol>"
                        },
                        CCBY: {
                            name: "Свободная (CC-BY)",
                            text: "<h1>知识共享（Creative Commons）署名 4.0公共许可协议国际版</h1>" +
                                "<h2>概括</h2>" +
                                "<i><b>免责声明。</b> Краткое описание (Commons Deed) не является юридическим инструментом. Это простое описание лицензии, написанное доступным языком изложение некоторых основных её положений. Представьте его как дружественный интерфейс к тексту лицензии. Сам по себе этот документ не обладает правовой силой, его содержимое не входит в состав лицензии.</i>" +
                                "<br/><br/>" +
                                '<h4>您可以自由地：</h4>' +
                                '<ul>' +
                                    '<li><strong>共享</strong>—在任何媒介以任何形式复制、发行本作品</li>' +
                                    '<li><strong>演绎</strong>—修改、转换或以本作品为基础进行创作</li>' +
                                    '<li>在任何用途下，甚至商业目的。</li>' +
                                '</ul>' +
                                '<ul>' +
                                    '<li>只要你遵守许可协议条款，许可人就无法收回你的这些权利。</li>' +
                                '</ul>' +
                                '<h4>惟须遵守下列条件：</h4>' +
                                '<ul>' +
                                    '<li><strong>署名</strong>—您必须给出适当的署名，提供指向本许可协议的链接，同时标明是否（对原始作品）作了修改。您可以用任何合理的方式来署名，但是不得以任何方式暗示许可人为您或您的使用背书。</li>' +
                                    '<li><strong>没有附加限制</strong>—您不得适用法律术语或者技术措施从而限制其他人做许可协议允许的事情。</li>' +
                                '</ul>' +
                                '<h4>声明：</h4>' +
                                '<ul>' +
                                    '<li>您不必因为公共领域的作品要素而遵守许可协议，或者您的使用被可适用的例外或限制所允许。</li>' +
                                    '<li>不提供担保。许可协议可能不会给与您意图使用的所必须的所有许可。例如，其他权利比如形象权、隐私权或人格权可能限制您如何使用作品。</li>' +
                                '</ul>' +
                                '<br/>' +
                                "<h2>许可证全文</h2>" +
                                '通过行使本协议所授予的权利（定义如下），您接受并同意受到知识共享（Creative Commons）署名 4.0国际公共许可协议（以下简称“本公共许可协议”）的约束。从合同解释的角度来看，您获得授权的对价是接受本协议的条款，许可人授予您这些权利的对价是可以通过采用本协议条款发布授权作品（material）而获得利益。' +
                                '<h4>第一条定义</h4>' +
                                '<ol>' +
                                    '<li><strong>演绎作品（Adapted Material）：</strong>指受到著作权与类似权利保护的，基于授权作品（Licensed Material）而创作的作品（material），例如对授权作品（Licensed Material）的翻译、改编、编排、改写或其他依据著作权与类似权利需要获得所有人许可的修改。为本公共许可协议之目的，当授权作品（Licensed Material）为音乐作品、表演或录音时，将其依时间序列关系与动态影像配合一致而形成的作品，视为演绎作品（Adapted Material）。</li>' +
                                    '<li><strong>演绎作者的许可：</strong>指您依据本公共许可协议对在演绎作品（Adapted Material）中自己所贡献的部分所享有的著作权与类似权利进行授权的协议。</li>' +
                                    '<li><strong>著作权与类似权利：</strong>指著作权和/或与著作权紧密联系的类似权利。类似权利包括但不限于：表演者权、广播组织权、录音录像制作者权、以及数据库特别权利，而不论上述权利的定义和归类如何。为本公共许可协议之目的，第二条b款第（1）项与第（2）项所列权利不属于著作权与类似权利。</li>' +
                                    '<li><strong>有效的技术措施：</strong>指根据各司法管辖区遵循《世界知识产权组织版权条约》（1996年12月20日通过）第十一条或类似国际协定项下的义务所制定的法律，在没有适当的授权的情况下，禁止使用者规避的技术措施。</li>' +
                                    '<li><strong>例外与限制：</strong>指合理使用（Fair Dealing and Fair Use）和/或其他适用于您对授权作品（Licensed Material）的使用的著作权与类似权利的例外或限制。</li>' +
                                    '<li><strong>授权作品（Licensed Material）：</strong>指许可人通过本公共许可协议授权的文学、艺术作品（artistic or literary work），数据库或其他作品（material）。</li>' +
                                    '<li><strong>协议所授予的权利：</strong>指依据本公共许可协议的条款和条件所授予您的各项权利，限于适用于您对授权作品（Licensed Material）的使用且许可人有权许可的著作权与类似权利。</li>' +
                                    '<li><strong>许可人：</strong>指通过本公共许可协议进行授权的个人或组织。</li>' +
                                    '<li><strong>分享：</strong>指以需要“协议所授予的权利”许可的任何方法或程序向公众提供作品（material），包括复制、公共展示、公开表演、发行、散布、传播、进口或提供作品（material）给公众以便其能在其选定的时间和地点接收作品（material）。</li>' +
                                    '<li><strong>数据库特别权利：</strong>指除了著作权之外，衍生于1996年3月11日通过的《欧洲议会与欧盟理事会关于数据库法律保护的指令》（Directive 96/9/EC）及其修改或后续版本的权利，或其他国家或地区本质上与之等同的权利。</li>' +
                                    '<li><strong>您：</strong>指依据本公共许可协议行使其所获得授予之权利的个人或机构。<strong>“您的”</strong>有相应的含义。</li>' +
                                '</ol>' +
                                '<h4>第二条授权范围</h4>' +
                                '<ol>' +
                                    '<li><strong>授权</strong><ol>' +
                                        '<li>根据本公共许可协议的条款，许可人授予您在全球范围内，免费的、不可再许可、非独占、不可撤销的许可，以对授权作品（Licensed Material）行使以下“协议所授予的权利”：<ol>' +
                                            '<li>复制和分享授权作品（Licensed Material）的全部或部分；以及</li>' +
                                            '<li>创作、复制和分享演绎作品（Adapted Material）。</li>' +
                                        '</ol></li>' +
                                        '<li><u>例外和限制</u>为避免疑义，若著作权的例外和限制适用于您对授权作品（Licensed Material）的使用，本公共许可协议将不适用，您也无须遵守本公共许可协议之条款。</li>' +
                                        '<li><u>期限</u>本公共许可协议的期限规定于第六条 a 款。</li>' +
                                        '<li><u>媒介和形式；允许的技术修改</u>许可人授权您在任何媒介以任何形式（不论目前已知的或未来出现的）行使本协议授予的权利，并为之进行必要的技术修改。许可人放弃和/或同意不主张任何权利以阻止您为了行使协议项下权利进行必要的技术修改，包括为规避有效技术措施所必须的技术修改。为了本公共许可协议之目的，基于第二条a款第（4）项进行的技术修改不构成演绎作品（Adapted Material）。</li>' +
                                        '<li><u>后续接受者</u><ol>' +
                                            '<li><u>来自许可人的要约——授权作品（Licensed Material）</u>本授权作品（Licensed Material）的每一个后续接受者都自动取得许可人的要约，以按照本公共许可协议的条款行使协议授予的权利。</li>' +
                                            '<li><u>禁止下游限制</u>若会限制授权作品（Licensed Material）后续接受者行使本协议所授予的权利，则您不得对授权作品（Licensed Material）提出或增加任何额外的或不同的条款，或使用任何有效技术措施。</li>' +
                                        '</ol></li>' +
                                        '<li><u>并非背书</u>本公共许可协议不构成、或不得被解释为允许您声明或主张：您或您对授权作品（Licensed Material）的使用与许可人或第三条a款第（1）项（A）目（i）所规定要求提供署名的权利人相关联，或得到其赞助、同意或被授予正式地位。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>其他权利</strong><ol>' +
                                        '<li>依据本公共许可协议，著作人身权，例如保护作品完整权、形象权、隐私权或其他类似的人格权利，不在许可范围内。但是，在条件允许的情况下，许可人可以在必要范围内放弃和/或同意不主张其权利，以便您行使本协议所授予的权利。</li>' +
                                        '<li>本公共许可协议不适用于任何专利权或商标权许可。</li>' +
                                        '<li>在自愿的或可放弃的法定或强制许可机制下，许可人在最大可能范围内放弃对您因行使本协议所授予的权利而产生的使用费的权利，不论是直接收取或通过集体管理组织收取。在其他任何情况下，许可人明确保留收取使用费的任何权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第三条授权条件</h4>' +
                                '您行使被许可的权利明确受以下条件限制：' +
                                '<ol>' +
                                    '<li><strong>署名</strong><ol>' +
                                        '<li>若您分享本授权作品（Licensed Material）（包含修改格式），您必须：<ol>' +
                                            '<li>保留如下标识（如果许可人提供授权作品（Licensed Material）的同时提供如下标识）：<ol>' +
                                                '<li>以许可人要求的任何合理方式，标识本授权作品（Licensed Material）创作者和其他被指定署名的人的身份（包括指定的笔名）；</li>' +
                                                '<li>著作权声明；</li>' +
                                                '<li>有关本公共许可协议的声明；</li>' +
                                                '<li>有关免责的声明；</li>' +
                                                '<li>在合理可行情况下，本授权作品（Licensed Material）的网址（URI）或超链接；</li>' +
                                            '</ol></li>' +
                                            '<li>表明您是否修改本授权作品（Licensed Material）及保留任何先前修改的标记；及</li>' +
                                            '<li>表明授权作品（Licensed Material）依据本公共许可协议授权，并提供本公共许可协议全文，或者本公共许可协议的网址（URI）或超链接。</li>' +
                                        '</ol></li>' +
                                        '<li>依据您分享本授权作品（Licensed Material）的媒介、方法及情況，您可以采用任何合理方式满足第三条a款第（1）项的条件。例如，提供包含所要求信息来源的网址（URI）或超链接可算是合理地满足此处的条件。</li>' +
                                        '<li>如果许可人要求，您必须在合理可行的范围内移除第三条a款第（1）项（A）目所要求的任何信息。</li>' +
                                        '<li>如果您分享您创作的演绎作品（Adapted Material），您适用的“演绎作者的许可”协议，不得使演绎作品（Adapted Material）的接收者无法遵守本公共许可协议。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第四条数据库特别权利</h4>' +
                                '当协议所授予的权利包含数据库特别权利，而该数据库特别权利适用于您对授权作品（Licensed Material）的使用时：' +
                                '<ol>' +
                                    '<li>为避免疑义，第二条a款第（1）项授权您，摘录、再利用、复制和分享全部或绝大部分数据库资料；</li>' +
                                    '<li>如果您将数据库资料的全部或绝大部分纳入您享有数据库特别权利的另一数据库，则您享有数据库特别权利的该数据库（而非其中的单个内容）视为演绎作品（Adapted Material）；</li>' +
                                    '<li>如果您分享全部或大部分该数据库的资料，您必须遵守第三条a款规定的条件。</li>' +
                                '</ol>' +
                                '为避免疑义，当协议所授予的权利包含其他著作权与类似权利时，第四条补充且不取代本公共许可协议所规定的您的义务。' +
                                '<h4>第五条免责声明及责任限制条款</h4>' +
                                '<ol>' +
                                    '<li><strong>除非许可人另有保证，否则在最大可能范围内，许可人按其现状和现有之基础提供授权作品（Licensed Material），且没有就授权作品（Licensed Material）做出任何形式的陈述或保证：无论明示、默示、法定或其他形式，包括但不限于任何有关本授权作品（Licensed Material）的权属保证、可交易性、适于特定目的、未侵害他人权利、没有潜在或其他瑕疵、精确性或是否有错误，不管是否已知或可发现。当免责声明全部或部分不被允许时，此免责声明可能不适用于您。</strong></li>' +
                                    '<li><strong>在最大可能范围内，对于任何因本公共许可协议或使用授权作品（Licensed Material）引起的直接的、特殊的、间接的、附随的、连带的、惩罚性的、警告性的，或其他的损失、成本、费用或损害，许可人不对您负任何法律上或其他的责任（包括但不限于过失责任）。当责任限制部分或全部不被允许时，该限制不适用于您。</strong></li>' +
                                '</ol>' +
                                '<ol start="3">' +
                                    '<li>前述免责及责任限制声明，应尽可能以最接近于完全排除全部责任的方式解释。</li>' +
                                '</ol>' +
                                '<h4>第六条期限与终止</h4>' +
                                '<ol>' +
                                    '<li>本公共许可协议在著作权与类似权利存续期间内有效。然而，如果您没有遵守此公共许可协议，则您依据此公共许可协议享有的权利自动终止。</li>' +
                                    '<li>当您使用本授权作品（Licensed Material）的权利根据第六条a款终止时，您的权利在下述情况下恢复：<ol>' +
                                        '<li>自违反协议的行为纠正之日起自动恢复，但须在您发现违反情形后30日内纠正；或</li>' +
                                        '<li>根据许可人明示恢复权利的意思表达。</li>' +
                                    '</ol>为避免疑义，本公共许可协议第六条b款不影响许可人就您违反本公共许可协议的行为寻求法律救济。</li>' +
                                    '<li>为避免疑义，许可人也可在任何时间，以另外的条款或条件提供本授权作品（Licensed Material），或者停止传播本授权作品（Licensed Material）；然而，许可人此种行为不会终止本公共许可协议。</li>' +
                                    '<li>本协议第一、五、六、七及第八条，不因本公共许可协议终止而失效。</li>' +
                                '</ol>' +
                                '<h4>第七条其他条款和条件</h4>' +
                                '<ol>' +
                                    '<li>除非明示同意，否则许可人不受您表达的任何附加或不同条款或条件约束。</li>' +
                                    '<li>本公共许可协议未提及的关于授权作品（Licensed Material）之任何安排、共识或协议，不属于且独立于本公共许可协议的条款及条件。</li>' +
                                '</ol>' +
                                '<h4>第八条解释</h4>' +
                                '<ol>' +
                                    '<li>为避免疑义，本许可协议不会也不应被解释为减少、限制、约束或施加条件于无需本公共许可协议授权即可依法行使的对授权作品（Licensed Material）的任何使用。</li>' +
                                    '<li>在最大可能范围内，如果本公共许可协议的任何条款被视为无法执行，该条款在必要的最小限度内，自动调整至可以执行。如果该条款不能被调整，其应自本公共许可协议中排除适用，不影响其余条款的效力。</li>' +
                                    '<li>除非许可人明示同意，本公共许可协议的任何条款或条件均不得放弃。</li>' +
                                    '<li>本公共许可协议条款不构成、也不得被解释为限制或者放弃适用于许可人或您的特权或豁免，包括豁免于任何司法管辖区或行政机构的法律程序。</li>' +
                                '</ol>',
                        },
                        CCBYSA: {
                            name: "Свободная (CC-BY-SA)",
                            text: "<h1>知识共享（Creative Commons）署名—相同方式共享 4.0公共许可协议国际版</h1>" +
                                "<h2>概括</h2>" +
                                "<i><b>免责声明。</b> Краткое описание (Commons Deed) не является юридическим инструментом. Это простое описание лицензии, написанное доступным языком изложение некоторых основных её положений. Представьте его как дружественный интерфейс к тексту лицензии. Сам по себе этот документ не обладает правовой силой, его содержимое не входит в состав лицензии.</i>" +
                                "<br/><br/>" +
                                '<h4>您可以自由地：</h4>' +
                                '<ul>' +
                                    '<li><strong>共享</strong>—在任何媒介以任何形式复制、发行本作品</li>' +
                                    '<li><strong>演绎</strong>—修改、转换或以本作品为基础进行创作</li>' +
                                    '<li>在任何用途下，甚至商业目的。</li>' +
                                '</ul>' +
                                '<ul>' +
                                    '<li>只要你遵守许可协议条款，许可人就无法收回你的这些权利。</li>' +
                                '</ul>' +
                                '<h4>惟须遵守下列条件：</h4>' +
                                '<ul>' +
                                    '<li><strong>署名</strong>—您必须给出适当的署名，提供指向本许可协议的链接，同时标明是否（对原始作品）作了修改。您可以用任何合理的方式来署名，但是不得以任何方式暗示许可人为您或您的使用背书。</li>' +
                                    '<li><strong>相同方式共享</strong>—如果您再混合、转换或者基于本作品进行创作，您必须基于与原先许可协议相同的许可协议分发您贡献的作品。</li>' +
                                    '<li><strong>没有附加限制</strong>—您不得适用法律术语或者技术措施从而限制其他人做许可协议允许的事情。</li>' +
                                '</ul>' +
                                '<h4>声明：</h4>' +
                                '<ul>' +
                                    '<li>您不必因为公共领域的作品要素而遵守许可协议，或者您的使用被可适用的例外或限制所允许。</li>' +
                                    '<li>不提供担保。许可协议可能不会给与您意图使用的所必须的所有许可。例如，其他权利比如形象权、隐私权或人格权可能限制您如何使用作品。</li>' +
                                '</ul>' +
                                '<br/>' +
                                "<h2>许可证全文</h2>" +
                                '通过行使本协议所授予的权利（定义如下），您接受并同意受到知识共享（Creative Commons）署名—相同方式共享4.0国际公共许可协议（以下简称“本公共许可协议”）的约束。从合同解释的角度来看，您获得授权的对价是接受本协议的条款，许可人授予您这些权利的对价是可以通过采用本协议条款发布授权作品（material）而获得利益。' +
                                '<h4>第一条定义</h4>' +
                                '<ol>' +
                                    '<li><strong>演绎作品（Adapted Material）：</strong>指受到著作权与类似权利保护的，基于授权作品（Licensed Material）而创作的作品（material），例如对授权作品（Licensed Material）的翻译、改编、编排、改写或其他依据著作权与类似权利需要获得所有人许可的修改。为本公共许可协议之目的，当授权作品（Licensed Material）为音乐作品、表演或录音时，将其依时间序列关系与动态影像配合一致而形成的作品，视为演绎作品（Adapted Material）。</li>' +
                                    '<li><strong>演绎作者的许可：</strong>指您依据本公共许可协议对在演绎作品（Adapted Material）中自己所贡献的部分所享有的著作权与类似权利进行授权的协议。</li>' +
                                    '<li><strong>署名—相同方式共享兼容协议：</strong>指在<a href="//creativecommons.org/compatiblelicenses"> creativecommons.org/compatiblelicenses</a>上列出且经知识共享组织（Creative Commons）认可、实质上与本公共许可协议相当的协议。</li>' +
                                    '<li><strong>著作权与类似权利：</strong>指著作权和/或与著作权紧密联系的类似权利。类似权利包括但不限于：表演者权、广播组织权、录音录像制作者权、以及数据库特别权利，而不论上述权利的定义和归类如何。为本公共许可协议之目的，第二条b款第（1）项与第（2）项所列权利不属于著作权与类似权利。</li>' +
                                    '<li><strong>有效的技术措施：</strong>指根据各司法管辖区遵循《世界知识产权组织版权条约》（1996年12月20日通过）第十一条或类似国际协定项下的义务所制定的法律，在没有适当的授权的情况下，禁止使用者规避的技术措施。</li>' +
                                    '<li><strong>例外与限制：</strong>指合理使用（Fair Dealing and Fair Use）和/或其他适用于您对授权作品（Licensed Material）的使用的著作权与类似权利的例外或限制。</li>' +
                                    '<li><strong>授权要素：</strong>指知识共享公共许可协议（CCPL）名称中所包含的协议特征。本公共许可协议的授权要素包括：署名和相同方式共享。</li>' +
                                    '<li><strong>授权作品（Licensed Material）：</strong>指许可人通过本公共许可协议授权的文学、艺术作品（artistic or literary work），数据库或其他作品（material）。</li>' +
                                    '<li><strong>协议所授予的权利：</strong>指依据本公共许可协议的条款和条件所授予您的各项权利，限于适用于您对授权作品（Licensed Material）的使用且许可人有权许可的著作权与类似权利。</li>' +
                                    '<li><strong>许可人：</strong>指通过本公共许可协议进行授权的个人或组织。</li>' +
                                    '<li><strong>分享：</strong>指以需要“协议所授予的权利”许可的任何方法或程序向公众提供作品（material），包括复制、公共展示、公开表演、发行、散布、传播、进口或提供作品（material）给公众以便其能在其选定的时间和地点接收作品（material）。</li>' +
                                    '<li><strong>数据库特别权利：</strong>指除了著作权之外，衍生于1996年3月11日通过的《欧洲议会与欧盟理事会关于数据库法律保护的指令》（Directive 96/9/EC）及其修改或后续版本的权利，或其他国家或地区本质上与之等同的权利。</li>' +
                                    '<li><strong>您：</strong>指依据本公共许可协议行使其所获得授予之权利的个人或机构。<strong>“您的”</strong>有相应的含义。</li>' +
                                '</ol>' +
                                '<h4>第二条授权范围</h4>' +
                                '<ol>' +
                                    '<li><strong>授权</strong><ol>' +
                                        '<li>根据本公共许可协议的条款，许可人授予您在全球范围内，免费的、不可再许可、非独占、不可撤销的许可，以对授权作品（Licensed Material）行使以下“协议所授予的权利”：<ol>' +
                                            '<li>复制和分享授权作品（Licensed Material）的全部或部分；以及</li>' +
                                            '<li>创作、复制和分享演绎作品（Adapted Material）。</li>' +
                                        '</ol></li>' +
                                        '<li><u>例外和限制</u>为避免疑义，若著作权的例外和限制适用于您对授权作品（Licensed Material）的使用，本公共许可协议将不适用，您也无须遵守本公共许可协议之条款。</li>' +
                                        '<li><u>期限</u>本公共许可协议的期限规定于第六条 a 款。</li>' +
                                        '<li><u>媒介和形式；允许的技术修改</u>许可人授权您在任何媒介以任何形式（不论目前已知的或未来出现的）行使本协议授予的权利，并为之进行必要的技术修改。许可人放弃和/或同意不主张任何权利以阻止您为了行使协议项下权利进行必要的技术修改，包括为规避有效技术措施所必须的技术修改。为了本公共许可协议之目的，基于第二条a款第（4）项进行的技术修改不构成演绎作品（Adapted Material）。</li>' +
                                        '<li><u>后续接受者</u><ol>' +
                                            '<li><u>来自许可人的要约——授权作品（Licensed Material）</u>本授权作品（Licensed Material）的每一个后续接受者都自动取得许可人的要约，以按照本公共许可协议的条款行使协议授予的权利。</li>' +
                                            '<li><u>来自许可人的额外要约——演绎作品（Adapted Material）</u>您基于授权作品（Licensed Material）创作的演绎作品（Adapted Material）的每一个后续接受者都自动取得许可人的要约，以按照您所适用的“演绎作者的许可”协议的条款行使协议所授予的权利。</li>' +
                                            '<li><u>禁止下游限制</u>若会限制授权作品（Licensed Material）后续接受者行使本协议所授予的权利，则您不得对授权作品（Licensed Material）提出或增加任何额外的或不同的条款，或使用任何有效技术措施。</li>' +
                                        '</ol></li>' +
                                        '<li><u>并非背书</u>本公共许可协议不构成、或不得被解释为允许您声明或主张：您或您对授权作品（Licensed Material）的使用与许可人或第三条a款第（1）项（A）目（i）所规定要求提供署名的权利人相关联，或得到其赞助、同意或被授予正式地位。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>其他权利</strong><ol>' +
                                        '<li>依据本公共许可协议，著作人身权，例如保护作品完整权、形象权、隐私权或其他类似的人格权利，不在许可范围内。但是，在条件允许的情况下，许可人可以在必要范围内放弃和/或同意不主张其权利，以便您行使本协议所授予的权利。</li>' +
                                        '<li>本公共许可协议不适用于任何专利权或商标权许可。</li>' +
                                        '<li>在自愿的或可放弃的法定或强制许可机制下，许可人在最大可能范围内放弃对您因行使本协议所授予的权利而产生的使用费的权利，不论是直接收取或通过集体管理组织收取。在其他任何情况下，许可人明确保留收取使用费的任何权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第三条授权条件</h4>' +
                                '<p>您行使被许可的权利明确受以下条件限制：</p>' +
                                '<ol>' +
                                    '<li><strong>署名</strong><ol>' +
                                        '<li>若您分享本授权作品（Licensed Material）（包含修改格式），您必须：<ol>' +
                                            '<li>保留如下标识（如果许可人提供授权作品（Licensed Material）的同时提供如下标识）：<ol>' +
                                                '<li>以许可人要求的任何合理方式，标识本授权作品（Licensed Material）创作者和其他被指定署名的人的身份（包括指定的笔名）；</li>' +
                                                '<li>著作权声明；</li>' +
                                                '<li>有关本公共许可协议的声明；</li>' +
                                                '<li>有关免责的声明；</li>' +
                                                '<li>在合理可行情况下，本授权作品（Licensed Material）的网址（URI）或超链接；</li>' +
                                            '</ol></li>' +
                                            '<li>表明您是否修改本授权作品（Licensed Material）及保留任何先前修改的标记；及</li>' +
                                            '<li>表明授权作品（Licensed Material）依据本公共许可协议授权，并提供本公共许可协议全文，或者本公共许可协议的网址（URI）或超链接。</li>' +
                                        '</ol></li>' +
                                        '<li>依据您分享本授权作品（Licensed Material）的媒介、方法及情況，您可以采用任何合理方式满足第三条a款第（1）项的条件。例如，提供包含所要求信息来源的网址（URI）或超链接可算是合理地满足此处的条件。</li>' +
                                        '<li>如果许可人要求，您必须在合理可行的范围内移除第三条a款第（1）项（A）目所要求的任何信息。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>相同方式共享</strong>除第三条a款的条件外，如果您分享您创作的演绎作品（Adapted Material），则下列条件也适用：<ol>' +
                                        '<li>您适用的“演绎作者的许可”协议必须是与本许可协议具有相同授权要素的知识共享（Creative Commons）许可协议（可以是本版本或后续版本），或者其他与“署名－相同方式共享”协议兼容的许可协议。</li>' +
                                        '<li>您必须提供您适用的“演绎作者的许可”协议全文或者该许可协议的网址（URI）或超链接。依据您分享您的演绎作品（Adapted Material）所使用的媒介、方法及情況，您可以采用任何合理方式满足此条件。</li>' +
                                        '<li>您不得提出或施加任何附加或不同的条款或条件、或在演绎作品（Adapted Material）上应用任何有效的技术措施，以限制使用者行使依您所适用的“演绎作者的许可”协议所授予的权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第四条数据库特别权利</h4>' +
                                '<p>当协议所授予的权利包含数据库特别权利，而该数据库特别权利适用于您对授权作品（Licensed Material）的使用时：</p>' +
                                '<ol>' +
                                    '<li>为避免疑义，第二条a款第（1）项授权您，摘录、再利用、复制和分享全部或绝大部分数据库资料；</li>' +
                                    '<li>如果您将数据库资料的全部或绝大部分纳入您享有数据库特别权利的另一数据库，则您享有数据库特别权利的该数据库（而非其中的单个内容）视为演绎作品（Adapted Material），适用第三条b款的要求；</li>' +
                                    '<li>如果您分享全部或大部分该数据库的资料，您必须遵守第三条a款规定的条件。</li>' +
                                '</ol>' +
                                '为避免疑义，当协议所授予的权利包含其他著作权与类似权利时，第四条补充且不取代本公共许可协议所规定的您的义务。' +
                                '<h4>第五条免责声明及责任限制条款</h4>' +
                                '<ol>' +
                                    '<li><strong>除非许可人另有保证，否则在最大可能范围内，许可人按其现状和现有之基础提供授权作品（Licensed Material），且没有就授权作品（Licensed Material）做出任何形式的陈述或保证：无论明示、默示、法定或其他形式，包括但不限于任何有关本授权作品（Licensed Material）的权属保证、可交易性、适于特定目的、未侵害他人权利、没有潜在或其他瑕疵、精确性或是否有错误，不管是否已知或可发现。当免责声明全部或部分不被允许时，此免责声明可能不适用于您。</strong></li>' +
                                    '<li><strong>在最大可能范围内，对于任何因本公共许可协议或使用授权作品（Licensed Material）引起的直接的、特殊的、间接的、附随的、连带的、惩罚性的、警告性的，或其他的损失、成本、费用或损害，许可人不对您负任何法律上或其他的责任（包括但不限于过失责任）。当责任限制部分或全部不被允许时，该限制不适用于您。</strong></li>' +
                                '</ol>' +
                                '<ol start="3">' +
                                    '<li>前述免责及责任限制声明，应尽可能以最接近于完全排除全部责任的方式解释。</li>' +
                                '</ol>' +
                                '<h4>第六条期限与终止</h4>' +
                                '<ol>' +
                                    '<li>本公共许可协议在著作权与类似权利存续期间内有效。然而，如果您没有遵守此公共许可协议，则您依据此公共许可协议享有的权利自动终止。</li>' +
                                    '<li>当您使用本授权作品（Licensed Material）的权利根据第六条a款终止时，您的权利在下述情况下恢复：<ol>' +
                                        '<li>自违反协议的行为纠正之日起自动恢复，但须在您发现违反情形后30日内纠正；或</li>' +
                                        '<li>根据许可人明示恢复权利的意思表达。</li>' +
                                    '</ol>为避免疑义，本公共许可协议第六条b款不影响许可人就您违反本公共许可协议的行为寻求法律救济。</li>' +
                                    '<li>为避免疑义，许可人也可在任何时间，以另外的条款或条件提供本授权作品（Licensed Material），或者停止传播本授权作品（Licensed Material）；然而，许可人此种行为不会终止本公共许可协议。</li>' +
                                    '<li>本协议第一、五、六、七及第八条，不因本公共许可协议终止而失效。</li>' +
                                '</ol>' +
                                '<h4>第七条其他条款和条件</h4>' +
                                '<ol>' +
                                    '<li>除非明示同意，否则许可人不受您表达的任何附加或不同条款或条件约束。</li>' +
                                    '<li>本公共许可协议未提及的关于授权作品（Licensed Material）之任何安排、共识或协议，不属于且独立于本公共许可协议的条款及条件。</li>' +
                                '</ol>' +
                                '<h4>第八条解释</h4>' +
                                '<ol>' +
                                    '<li>为避免疑义，本许可协议不会也不应被解释为减少、限制、约束或施加条件于无需本公共许可协议授权即可依法行使的对授权作品（Licensed Material）的任何使用。</li>' +
                                    '<li>在最大可能范围内，如果本公共许可协议的任何条款被视为无法执行，该条款在必要的最小限度内，自动调整至可以执行。如果该条款不能被调整，其应自本公共许可协议中排除适用，不影响其余条款的效力。</li>' +
                                    '<li>除非许可人明示同意，本公共许可协议的任何条款或条件均不得放弃。</li>' +
                                    '<li>本公共许可协议条款不构成、也不得被解释为限制或者放弃适用于许可人或您的特权或豁免，包括豁免于任何司法管辖区或行政机构的法律程序。</li>' +
                                '</ol>',
                        },
                        CCBYND: {
                            name: "Несвободная (CC-BY-ND)",
                            text: "<h1>知识共享（Creative Commons）署名—禁止演绎 4.0公共许可协议国际版</h1>" +
                                "<h2>概括</h2>" +
                                "<i><b>免责声明。</b> Краткое описание (Commons Deed) не является юридическим инструментом. Это простое описание лицензии, написанное доступным языком изложение некоторых основных её положений. Представьте его как дружественный интерфейс к тексту лицензии. Сам по себе этот документ не обладает правовой силой, его содержимое не входит в состав лицензии.</i>" +
                                "<br/><br/>" +
                                '<h4>您可以自由地：</h4>' +
                                '<ul>' +
                                    '<li><strong>共享</strong>—在任何媒介以任何形式复制、发行本作品</li>' +
                                    '<li>在任何用途下，甚至商业目的。</li>' +
                                '</ul>' +
                                '<ul>' +
                                    '<li>只要你遵守许可协议条款，许可人就无法收回你的这些权利。</li>' +
                                '</ul>' +
                                '<h4>惟须遵守下列条件：</h4>' +
                                '<ul>' +
                                    '<li><strong>署名</strong>—您必须给出适当的署名，提供指向本许可协议的链接，同时标明是否（对原始作品）作了修改。您可以用任何合理的方式来署名，但是不得以任何方式暗示许可人为您或您的使用背书。</li>' +
                                    '<li><strong>禁止演绎</strong>—如果您再混合、转换、或者基于该作品创作，您不可以分发修改作品。</li>' +
                                    '<li><strong>没有附加限制</strong>—您不得适用法律术语或者技术措施从而限制其他人做许可协议允许的事情。</li>' +
                                '</ul>' +
                                '<h4>声明：</h4>' +
                                '<ul>' +
                                    '<li>您不必因为公共领域的作品要素而遵守许可协议，或者您的使用被可适用的例外或限制所允许。</li>' +
                                    '<li>不提供担保。许可协议可能不会给与您意图使用的所必须的所有许可。例如，其他权利比如形象权、隐私权或人格权可能限制您如何使用作品。</li>' +
                                '</ul>' +
                                '<br/>' +
                                "<h2>许可证全文</h2>" +
                                '通过行使本协议所授予的权利（定义如下），您接受并同意受到知识共享（Creative Commons）署名—禁止演绎4.0国际公共许可协议（以下简称“本公共许可协议”）的约束。从合同解释的角度来看，您获得授权的对价是接受本协议的条款，许可人授予您这些权利的对价是可以通过采用本协议条款发布授权作品（material）而获得利益。' +
                                '<h4>第一条定义</h4>' +
                                '<ol>' +
                                    '<li><strong>演绎作品（Adapted Material）：</strong>指受到著作权与类似权利保护的，基于授权作品（Licensed Material）而创作的作品（material），例如对授权作品（Licensed Material）的翻译、改编、编排、改写或其他依据著作权与类似权利需要获得所有人许可的修改。为本公共许可协议之目的，当授权作品（Licensed Material）为音乐作品、表演或录音时，将其依时间序列关系与动态影像配合一致而形成的作品，视为演绎作品（Adapted Material）。</li>' +
                                    '<li><strong>著作权与类似权利：</strong>指著作权和/或与著作权紧密联系的类似权利。类似权利包括但不限于：表演者权、广播组织权、录音录像制作者权、以及数据库特别权利，而不论上述权利的定义和归类如何。为本公共许可协议之目的，第二条b款第（1）项与第（2）项所列权利不属于著作权与类似权利。</li>' +
                                    '<li><strong>有效的技术措施：</strong>指根据各司法管辖区遵循《世界知识产权组织版权条约》（1996年12月20日通过）第十一条或类似国际协定项下的义务所制定的法律，在没有适当的授权的情况下，禁止使用者规避的技术措施。</li>' +
                                    '<li><strong>例外与限制：</strong>指合理使用（Fair Dealing and Fair Use）和/或其他适用于您对授权作品（Licensed Material）的使用的著作权与类似权利的例外或限制。</li>' +
                                    '<li><strong>授权作品（Licensed Material）：</strong>指许可人通过本公共许可协议授权的文学、艺术作品（artistic or literary work），数据库或其他作品（material）。</li>' +
                                    '<li><strong>协议所授予的权利：</strong>指依据本公共许可协议的条款和条件所授予您的各项权利，限于适用于您对授权作品（Licensed Material）的使用且许可人有权许可的著作权与类似权利。</li>' +
                                    '<li><strong>许可人：</strong>指通过本公共许可协议进行授权的个人或组织。</li>' +
                                    '<li><strong>分享：</strong>指以需要“协议所授予的权利”许可的任何方法或程序向公众提供作品（material），包括复制、公共展示、公开表演、发行、散布、传播、进口或提供作品（material）给公众以便其能在其选定的时间和地点接收作品（material）。</li>' +
                                    '<li><strong>数据库特别权利：</strong>指除了著作权之外，衍生于1996年3月11日通过的《欧洲议会与欧盟理事会关于数据库法律保护的指令》（Directive 96/9/EC）及其修改或后续版本的权利，或其他国家或地区本质上与之等同的权利。</li>' +
                                    '<li><strong>您：</strong>指依据本公共许可协议行使其所获得授予之权利的个人或机构。<strong>“您的”</strong>有相应的含义。</li>' +
                                '</ol>' +
                                '<h4>第二条授权范围</h4>' +
                                '<ol>' +
                                    '<li><strong>授权</strong><ol>' +
                                        '<li>根据本公共许可协议的条款，许可人授予您在全球范围内，免费的、不可再许可、非独占、不可撤销的许可，以对授权作品（Licensed Material）行使以下“协议所授予的权利”：<ol>' +
                                            '<li>复制和分享授权作品（Licensed Material）的全部或部分；以及</li>' +
                                            '<li>创作或复制演绎作品，但不得分享该演绎作品（Adapted Material）。</li>' +
                                        '</ol></li>' +
                                        '<li><u>例外和限制</u>为避免疑义，若著作权的例外和限制适用于您对授权作品（Licensed Material）的使用，本公共许可协议将不适用，您也无须遵守本公共许可协议之条款。</li>' +
                                        '<li><u>期限</u>本公共许可协议的期限规定于第六条 a 款。</li>' +
                                        '<li><u>媒介和形式；允许的技术修改</u>许可人授权您在任何媒介以任何形式（不论目前已知的或未来出现的）行使本协议授予的权利，并为之进行必要的技术修改。许可人放弃和/或同意不主张任何权利以阻止您为了行使协议项下权利进行必要的技术修改，包括为规避有效技术措施所必须的技术修改。为了本公共许可协议之目的，基于第二条a款第（4）项进行的技术修改不构成演绎作品（Adapted Material）。</li>' +
                                        '<li><u>后续接受者</u><ol>' +
                                            '<li><u>来自许可人的要约——授权作品（Licensed Material）</u>本授权作品（Licensed Material）的每一个后续接受者都自动取得许可人的要约，以按照本公共许可协议的条款行使协议授予的权利。</li>' +
                                            '<li><u>禁止下游限制</u>若会限制授权作品（Licensed Material）后续接受者行使本协议所授予的权利，则您不得对授权作品（Licensed Material）提出或增加任何额外的或不同的条款，或使用任何有效技术措施。</li>' +
                                        '</ol></li>' +
                                        '<li><u>并非背书</u>本公共许可协议不构成、或不得被解释为允许您声明或主张：您或您对授权作品（Licensed Material）的使用与许可人或第三条a款第（1）项（A）目（i）所规定要求提供署名的权利人相关联，或得到其赞助、同意或被授予正式地位。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>其他权利</strong><ol>' +
                                        '<li>依据本公共许可协议，著作人身权，例如保护作品完整权、形象权、隐私权或其他类似的人格权利，不在许可范围内。但是，在条件允许的情况下，许可人可以在必要范围内放弃和/或同意不主张其权利，以便您行使本协议所授予的权利。</li>' +
                                        '<li>本公共许可协议不适用于任何专利权或商标权许可。</li>' +
                                        '<li>在自愿的或可放弃的法定或强制许可机制下，许可人在最大可能范围内放弃对您因行使本协议所授予的权利而产生的使用费的权利，不论是直接收取或通过集体管理组织收取。在其他任何情况下，许可人明确保留收取使用费的任何权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第三条授权条件</h4>' +
                                '您行使被许可的权利明确受以下条件限制：' +
                                '<ol>' +
                                    '<li><strong>署名</strong><ol>' +
                                        '<li><p>若您分享本授权作品（Licensed Material），您必须：</p><ol>' +
                                            '<li>保留如下标识（如果许可人提供授权作品（Licensed Material）的同时提供如下标识）：<ol>' +
                                                '<li>以许可人要求的任何合理方式，标识本授权作品（Licensed Material）创作者和其他被指定署名的人的身份（包括指定的笔名）；</li>' +
                                                '<li>著作权声明；</li>' +
                                                '<li>有关本公共许可协议的声明；</li>' +
                                                '<li>有关免责的声明；</li>' +
                                                '<li>在合理可行情况下，本授权作品（Licensed Material）的网址（URI）或超链接；</li>' +
                                            '</ol>为避免疑义，依据本公共许可协议，您没有被授予分享演绎作品（Adapted Material）的许可。</li>' +
                                            '<li>表明您是否修改本授权作品（Licensed Material）及保留任何先前修改的标记；及</li>' +
                                            '<li>表明授权作品（Licensed Material）依据本公共许可协议授权，并提供本公共许可协议全文，或者本公共许可协议的网址（URI）或超链接。</li>' +
                                        '</ol></li>' +
                                        '<li>依据您分享本授权作品（Licensed Material）的媒介、方法及情況，您可以采用任何合理方式满足第三条a款第（1）项的条件。例如，提供包含所要求信息来源的网址（URI）或超链接可算是合理地满足此处的条件。</li>' +
                                        '<li>如果许可人要求，您必须在合理可行的范围内移除第三条a款第（1）项（A）目所要求的任何信息。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第四条数据库特别权利</h4>' +
                                '当协议所授予的权利包含数据库特别权利，而该数据库特别权利适用于您对授权作品（Licensed Material）的使用时：' +
                                '<ol>' +
                                    '<li>为避免疑义，第二条a款第（1）项授权您，摘录、再利用、复制和分享全部或绝大部分数据库资料,但不得分享演绎作品；</li>' +
                                    '<li>如果您将数据库资料的全部或绝大部分纳入您享有数据库特别权利的另一数据库，则您享有数据库特别权利的该数据库（而非其中的单个内容）视为演绎作品（Adapted Material）；</li>' +
                                    '<li>如果您分享全部或大部分该数据库的资料，您必须遵守第三条a款规定的条件。</li>' +
                                '</ol>' +
                                '为避免疑义，当协议所授予的权利包含其他著作权与类似权利时，第四条补充且不取代本公共许可协议所规定的您的义务。' +
                                '<h4>第五条免责声明及责任限制条款</h4>' +
                                '<ol>' +
                                    '<li><strong>除非许可人另有保证，否则在最大可能范围内，许可人按其现状和现有之基础提供授权作品（Licensed Material），且没有就授权作品（Licensed Material）做出任何形式的陈述或保证：无论明示、默示、法定或其他形式，包括但不限于任何有关本授权作品（Licensed Material）的权属保证、可交易性、适于特定目的、未侵害他人权利、没有潜在或其他瑕疵、精确性或是否有错误，不管是否已知或可发现。当免责声明全部或部分不被允许时，此免责声明可能不适用于您。</strong></li>' +
                                    '<li><strong>在最大可能范围内，对于任何因本公共许可协议或使用授权作品（Licensed Material）引起的直接的、特殊的、间接的、附随的、连带的、惩罚性的、警告性的，或其他的损失、成本、费用或损害，许可人不对您负任何法律上或其他的责任（包括但不限于过失责任）。当责任限制部分或全部不被允许时，该限制不适用于您。</strong></li>' +
                                '</ol>' +
                                '<ol start="3">' +
                                    '<li>前述免责及责任限制声明，应尽可能以最接近于完全排除全部责任的方式解释。</li>' +
                                '</ol>' +
                                '<h4>第六条期限与终止</h4>' +
                                '<ol>' +
                                    '<li>本公共许可协议在著作权与类似权利存续期间内有效。然而，如果您没有遵守此公共许可协议，则您依据此公共许可协议享有的权利自动终止。</li>' +
                                    '<li>当您使用本授权作品（Licensed Material）的权利根据第六条a款终止时，您的权利在下述情况下恢复：<ol>' +
                                        '<li>自违反协议的行为纠正之日起自动恢复，但须在您发现违反情形后30日内纠正；或</li>' +
                                        '<li>根据许可人明示恢复权利的意思表达。</li>' +
                                    '</ol>为避免疑义，本公共许可协议第六条b款不影响许可人就您违反本公共许可协议的行为寻求法律救济。</li>' +
                                    '<li>为避免疑义，许可人也可在任何时间，以另外的条款或条件提供本授权作品（Licensed Material），或者停止传播本授权作品（Licensed Material）；然而，许可人此种行为不会终止本公共许可协议。</li>' +
                                    '<li>本协议第一、五、六、七及第八条，不因本公共许可协议终止而失效。</li>' +
                                '</ol>' +
                                '<h4>第七条其他条款和条件</h4>' +
                                '<ol>' +
                                    '<li>除非明示同意，否则许可人不受您表达的任何附加或不同条款或条件约束。</li>' +
                                    '<li>本公共许可协议未提及的关于授权作品（Licensed Material）之任何安排、共识或协议，不属于且独立于本公共许可协议的条款及条件。</li>' +
                                '</ol>' +
                                '<h4>第八条解释</h4>' +
                                '<ol>' +
                                    '<li>为避免疑义，本许可协议不会也不应被解释为减少、限制、约束或施加条件于无需本公共许可协议授权即可依法行使的对授权作品（Licensed Material）的任何使用。</li>' +
                                    '<li>在最大可能范围内，如果本公共许可协议的任何条款被视为无法执行，该条款在必要的最小限度内，自动调整至可以执行。如果该条款不能被调整，其应自本公共许可协议中排除适用，不影响其余条款的效力。</li>' +
                                    '<li>除非许可人明示同意，本公共许可协议的任何条款或条件均不得放弃。</li>' +
                                    '<li>本公共许可协议条款不构成、也不得被解释为限制或者放弃适用于许可人或您的特权或豁免，包括豁免于任何司法管辖区或行政机构的法律程序。</li>' +
                                '</ol>',
                        },
                        CCBYNC: {
                            name: "Несвободная (CC-BY-NC)",
                            text: "<h1>知识共享（Creative Commons）署名—非商业性使用 4.0公共许可协议国际版</h1>" +
                                "<h2>概括</h2>" +
                                "<i><b>免责声明。</b> Краткое описание (Commons Deed) не является юридическим инструментом. Это простое описание лицензии, написанное доступным языком изложение некоторых основных её положений. Представьте его как дружественный интерфейс к тексту лицензии. Сам по себе этот документ не обладает правовой силой, его содержимое не входит в состав лицензии.</i>" +
                                "<br/><br/>" +
                                '<h4>您可以自由地：</h4>' +
                                '<ul>' +
                                    '<li><strong>共享</strong>—在任何媒介以任何形式复制、发行本作品</li>' +
                                    '<li><strong>演绎</strong>—修改、转换或以本作品为基础进行创作</li>' +
                                '</ul>' +
                                '<ul>' +
                                    '<li>只要你遵守许可协议条款，许可人就无法收回你的这些权利。</li>' +
                                '</ul>' +
                                '<h4>惟须遵守下列条件：</h4>' +
                                '<ul>' +
                                    '<li><strong>署名</strong>—您必须给出适当的署名，提供指向本许可协议的链接，同时标明是否（对原始作品）作了修改。您可以用任何合理的方式来署名，但是不得以任何方式暗示许可人为您或您的使用背书。</li>' +
                                    '<li><strong>非商业性使用</strong>—您不得将本作品用于商业目的。</li>' +
                                     '<li><strong>没有附加限制</strong>—您不得适用法律术语或者技术措施从而限制其他人做许可协议允许的事情。</li>' +
                                '</ul>' +
                                '<h4>声明：</h4>' +
                                '<ul>' +
                                    '<li>您不必因为公共领域的作品要素而遵守许可协议，或者您的使用被可适用的例外或限制所允许。</li>' +
                                    '<li>不提供担保。许可协议可能不会给与您意图使用的所必须的所有许可。例如，其他权利比如形象权、隐私权或人格权可能限制您如何使用作品。</li>' +
                                '</ul>' +
                                '<br/>' +
                                "<h2>许可证全文</h2>" +
                                '通过行使本协议所授予的权利（定义如下），您接受并同意受到知识共享（Creative Commons）署名—非商业性使用4.0国际公共许可协议（以下简称“本公共许可协议”）的约束。从合同解释的角度来看，您获得授权的对价是接受本协议的条款，许可人授予您这些权利的对价是可以通过采用本协议条款发布授权作品（material）而获得利益。' +
                                '<h4>第一条定义</h4>' +
                                '<ol>' +
                                    '<li><strong>演绎作品（Adapted Material）：</strong>指受到著作权与类似权利保护的，基于授权作品（Licensed Material）而创作的作品（material），例如对授权作品（Licensed Material）的翻译、改编、编排、改写或其他依据著作权与类似权利需要获得所有人许可的修改。为本公共许可协议之目的，当授权作品（Licensed Material）为音乐作品、表演或录音时，将其依时间序列关系与动态影像配合一致而形成的作品，视为演绎作品（Adapted Material）。</li>' +
                                    '<li><strong>演绎作者的许可：</strong>指您依据本公共许可协议对在演绎作品（Adapted Material）中自己所贡献的部分所享有的著作权与类似权利进行授权的协议。</li>' +
                                    '<li><strong>著作权与类似权利：</strong>指著作权和/或与著作权紧密联系的类似权利。类似权利包括但不限于：表演者权、广播组织权、录音录像制作者权、以及数据库特别权利，而不论上述权利的定义和归类如何。为本公共许可协议之目的，第二条b款第（1）项与第（2）项所列权利不属于著作权与类似权利。</li>' +
                                    '<li><strong>有效的技术措施：</strong>指根据各司法管辖区遵循《世界知识产权组织版权条约》（1996年12月20日通过）第十一条或类似国际协定项下的义务所制定的法律，在没有适当的授权的情况下，禁止使用者规避的技术措施。</li>' +
                                    '<li><strong>例外与限制：</strong>指合理使用（Fair Dealing and Fair Use）和/或其他适用于您对授权作品（Licensed Material）的使用的著作权与类似权利的例外或限制。</li>' +
                                    '<li><strong>授权作品（Licensed Material）：</strong>指许可人通过本公共许可协议授权的文学、艺术作品（artistic or literary work），数据库或其他作品（material）。</li>' +
                                    '<li><strong>协议所授予的权利：</strong>指依据本公共许可协议的条款和条件所授予您的各项权利，限于适用于您对授权作品（Licensed Material）的使用且许可人有权许可的著作权与类似权利。</li>' +
                                    '<li><strong>许可人：</strong>指通过本公共许可协议进行授权的个人或组织。</li>' +
                                    '<li><strong>非商业性使用：</strong>指该使用的主要意图或者指向并非获取商业优势或金钱报酬。为本公共许可协议之目的，以数字文件共享或类似方式，用授权作品（Licensed Material）交换其他受到著作权与类似权利保护的作品（material）是非商业性使用，只要该交换不涉及金钱报酬的支付。</li>' +
                                    '<li><strong>分享：</strong>指以需要“协议所授予的权利”许可的任何方法或程序向公众提供作品（material），包括复制、公共展示、公开表演、发行、散布、传播、进口或提供作品（material）给公众以便其能在其选定的时间和地点接收作品（material）。</li>' +
                                    '<li><strong>数据库特别权利：</strong>指除了著作权之外，衍生于1996年3月11日通过的《欧洲议会与欧盟理事会关于数据库法律保护的指令》（Directive 96/9/EC）及其修改或后续版本的权利，或其他国家或地区本质上与之等同的权利。</li>' +
                                    '<li><strong>您：</strong>指依据本公共许可协议行使其所获得授予之权利的个人或机构。<strong>“您的”</strong>有相应的含义。</li>' +
                                '</ol>' +
                                '<h4>第二条授权范围</h4>' +
                                '<ol>' +
                                    '<li><strong>授权</strong><ol>' +
                                        '<li>根据本公共许可协议的条款，许可人授予您在全球范围内，免费的、不可再许可、非独占、不可撤销的许可，以对授权作品（Licensed Material）行使以下“协议所授予的权利”：<ol>' +
                                            '<li>复制和分享授权作品（Licensed Material）的全部或部分，仅限于非商业性使用；以及</li>' +
                                            '<li>为非商业目的创作、复制和分享演绎作品（Adapted Material）。</li>' +
                                        '</ol></li>' +
                                        '<li><u>例外和限制</u>为避免疑义，若著作权的例外和限制适用于您对授权作品（Licensed Material）的使用，本公共许可协议将不适用，您也无须遵守本公共许可协议之条款。</li>' +
                                        '<li><u>期限</u>本公共许可协议的期限规定于第六条 a 款。</li>' +
                                        '<li><u>媒介和形式；允许的技术修改</u>许可人授权您在任何媒介以任何形式（不论目前已知的或未来出现的）行使本协议授予的权利，并为之进行必要的技术修改。许可人放弃和/或同意不主张任何权利以阻止您为了行使协议项下权利进行必要的技术修改，包括为规避有效技术措施所必须的技术修改。为了本公共许可协议之目的，基于第二条a款第（4）项进行的技术修改不构成演绎作品（Adapted Material）。</li>' +
                                        '<li><u>后续接受者</u><ol>' +
                                            '<li><u>来自许可人的要约——授权作品（Licensed Material）</u>本授权作品（Licensed Material）的每一个后续接受者都自动取得许可人的要约，以按照本公共许可协议的条款行使协议授予的权利。</li>' +
                                            '<li><u>禁止下游限制</u>若会限制授权作品（Licensed Material）后续接受者行使本协议所授予的权利，则您不得对授权作品（Licensed Material）提出或增加任何额外的或不同的条款，或使用任何有效技术措施。</li>' +
                                        '</ol></li>' +
                                        '<li><u>并非背书</u>本公共许可协议不构成、或不得被解释为允许您声明或主张：您或您对授权作品（Licensed Material）的使用与许可人或第三条a款第（1）项（A）目（i）所规定要求提供署名的权利人相关联，或得到其赞助、同意或被授予正式地位。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>其他权利</strong><ol>' +
                                        '<li>依据本公共许可协议，著作人身权，例如保护作品完整权、形象权、隐私权或其他类似的人格权利，不在许可范围内。但是，在条件允许的情况下，许可人可以在必要范围内放弃和/或同意不主张其权利，以便您行使本协议所授予的权利。</li>' +
                                        '<li>本公共许可协议不适用于任何专利权或商标权许可。</li>' +
                                        '<li>在自愿的或可放弃的法定或强制许可机制下，许可人在最大可能范围内放弃对您因行使本协议所授予的权利而产生的使用费的权利，不论是直接收取或通过集体管理组织收取。在其他任何情况下（包括授权作品（Licensed Material）被商业性使用的情形），许可人明确保留收取使用费的任何权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第三条授权条件</h4>' +
                                '您行使被许可的权利明确受以下条件限制：' +
                                '<ol>' +
                                    '<li><strong>署名</strong><ol>' +
                                        '<li>若您分享本授权作品（Licensed Material）（包含修改格式），您必须：<ol>' +
                                            '<li>保留如下标识（如果许可人提供授权作品（Licensed Material）的同时提供如下标识）：<ol>' +
                                                '<li>以许可人要求的任何合理方式，标识本授权作品（Licensed Material）创作者和其他被指定署名的人的身份（包括指定的笔名）；</li>' +
                                                '<li>著作权声明；</li>' +
                                                '<li>有关本公共许可协议的声明；</li>' +
                                                '<li>有关免责的声明；</li>' +
                                                '<li>在合理可行情况下，本授权作品（Licensed Material）的网址（URI）或超链接；</li>' +
                                            '</ol></li>' +
                                            '<li>表明您是否修改本授权作品（Licensed Material）及保留任何先前修改的标记；及</li>' +
                                            '<li>表明授权作品（Licensed Material）依据本公共许可协议授权，并提供本公共许可协议全文，或者本公共许可协议的网址（URI）或超链接。</li>' +
                                        '</ol></li>' +
                                        '<li>依据您分享本授权作品（Licensed Material）的媒介、方法及情況，您可以采用任何合理方式满足第三条a款第（1）项的条件。例如，提供包含所要求信息来源的网址（URI）或超链接可算是合理地满足此处的条件。</li>' +
                                        '<li>如果许可人要求，您必须在合理可行的范围内移除第三条a款第（1）项（A）目所要求的任何信息。</li>' +
                                        '<li>如果您分享您创作的演绎作品（Adapted Material），您适用的“演绎作者的许可”协议，不得使演绎作品（Adapted Material）的接收者无法遵守本公共许可协议。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第四条数据库特别权利</h4>' +
                                '<p>当协议所授予的权利包含数据库特别权利，而该数据库特别权利适用于您对授权作品（Licensed Material）的使用时：</p>' +
                                '<ol>' +
                                    '<li>为避免疑义，第二条a款第（1）项授权您，仅限于以非商业性目的，摘录、再利用、复制和分享全部或绝大部分数据库资料；</li>' +
                                    '<li>如果您将数据库资料的全部或绝大部分纳入您享有数据库特别权利的另一数据库，则您享有数据库特别权利的该数据库（而非其中的单个内容）视为演绎作品（Adapted Material）；</li>' +
                                    '<li>如果您分享全部或大部分该数据库的资料，您必须遵守第三条a款规定的条件。</li>' +
                                '</ol>' +
                                '为避免疑义，当协议所授予的权利包含其他著作权与类似权利时，第四条补充且不取代本公共许可协议所规定的您的义务。' +
                                '<h4>第五条免责声明及责任限制条款</h4>' +
                                '<ol>' +
                                    '<li><strong>除非许可人另有保证，否则在最大可能范围内，许可人按其现状和现有之基础提供授权作品（Licensed Material），且没有就授权作品（Licensed Material）做出任何形式的陈述或保证：无论明示、默示、法定或其他形式，包括但不限于任何有关本授权作品（Licensed Material）的权属保证、可交易性、适于特定目的、未侵害他人权利、没有潜在或其他瑕疵、精确性或是否有错误，不管是否已知或可发现。当免责声明全部或部分不被允许时，此免责声明可能不适用于您。</strong></li>' +
                                    '<li><strong>在最大可能范围内，对于任何因本公共许可协议或使用授权作品（Licensed Material）引起的直接的、特殊的、间接的、附随的、连带的、惩罚性的、警告性的，或其他的损失、成本、费用或损害，许可人不对您负任何法律上或其他的责任（包括但不限于过失责任）。当责任限制部分或全部不被允许时，该限制不适用于您。</strong></li>' +
                                '</ol>' +
                                '<ol start="3">' +
                                    '<li>前述免责及责任限制声明，应尽可能以最接近于完全排除全部责任的方式解释。</li>' +
                                '</ol>' +
                                '<h4>第六条期限与终止</h4>' +
                                '<ol>' +
                                    '<li>本公共许可协议在著作权与类似权利存续期间内有效。然而，如果您没有遵守此公共许可协议，则您依据此公共许可协议享有的权利自动终止。</li>' +
                                    '<li>当您使用本授权作品（Licensed Material）的权利根据第六条a款终止时，您的权利在下述情况下恢复：<ol>' +
                                        '<li>自违反协议的行为纠正之日起自动恢复，但须在您发现违反情形后30日内纠正；或</li>' +
                                        '<li>根据许可人明示恢复权利的意思表达。</li>' +
                                    '</ol>为避免疑义，本公共许可协议第六条b款不影响许可人就您违反本公共许可协议的行为寻求法律救济。</li>' +
                                    '<li>为避免疑义，许可人也可在任何时间，以另外的条款或条件提供本授权作品（Licensed Material），或者停止传播本授权作品（Licensed Material）；然而，许可人此种行为不会终止本公共许可协议。</li>' +
                                    '<li>本协议第一、五、六、七及第八条，不因本公共许可协议终止而失效。</li>' +
                                '</ol>' +
                                '<h4>第七条其他条款和条件</h4>' +
                                '<ol>' +
                                    '<li>除非明示同意，否则许可人不受您表达的任何附加或不同条款或条件约束。</li>' +
                                    '<li>本公共许可协议未提及的关于授权作品（Licensed Material）之任何安排、共识或协议，不属于且独立于本公共许可协议的条款及条件。</li>' +
                                '</ol>' +
                                '<h4>第八条解释</h4>' +
                                '<ol>' +
                                    '<li>为避免疑义，本许可协议不会也不应被解释为减少、限制、约束或施加条件于无需本公共许可协议授权即可依法行使的对授权作品（Licensed Material）的任何使用。</li>' +
                                    '<li>在最大可能范围内，如果本公共许可协议的任何条款被视为无法执行，该条款在必要的最小限度内，自动调整至可以执行。如果该条款不能被调整，其应自本公共许可协议中排除适用，不影响其余条款的效力。</li>' +
                                    '<li>除非许可人明示同意，本公共许可协议的任何条款或条件均不得放弃。</li>' +
                                    '<li>本公共许可协议条款不构成、也不得被解释为限制或者放弃适用于许可人或您的特权或豁免，包括豁免于任何司法管辖区或行政机构的法律程序。</li>' +
                                '</ol>',
                        },
                        CCBYNCND: {
                            name: "Несвободная (CC-BY-NC-ND)",
                            text: "<h1>知识共享（Creative Commons）署名—非商业性使用—禁止演绎 4.0公共许可协议国际版</h1>" +
                                "<h2>概括</h2>" +
                                "<i><b>免责声明。</b> Краткое описание (Commons Deed) не является юридическим инструментом. Это простое описание лицензии, написанное доступным языком изложение некоторых основных её положений. Представьте его как дружественный интерфейс к тексту лицензии. Сам по себе этот документ не обладает правовой силой, его содержимое не входит в состав лицензии.</i>" +
                                "<br/><br/>" +
                                '<h4>您可以自由地：</h4>' +
                                '<ul>' +
                                    '<li><strong>共享</strong>—在任何媒介以任何形式复制、发行本作品</li>' +
                                '</ul>' +
                                '<ul>' +
                                    '<li>只要你遵守许可协议条款，许可人就无法收回你的这些权利。</li>' +
                                '</ul>' +
                                '<h4>惟须遵守下列条件：</h4>' +
                                '<ul>' +
                                    '<li><strong>署名</strong>—您必须给出适当的署名，提供指向本许可协议的链接，同时标明是否（对原始作品）作了修改。您可以用任何合理的方式来署名，但是不得以任何方式暗示许可人为您或您的使用背书。</li>' +
                                    '<li><strong>非商业性使用</strong>—您不得将本作品用于商业目的。</li>' +
                                    '<li><strong>禁止演绎</strong>—如果您再混合、转换、或者基于该作品创作，您不可以分发修改作品。</li>' +
                                    '<li><strong>没有附加限制</strong>—您不得适用法律术语或者技术措施从而限制其他人做许可协议允许的事情。</li>' +
                                '</ul>' +
                                '<h4>声明：</h4>' +
                                '<ul>' +
                                    '<li>您不必因为公共领域的作品要素而遵守许可协议，或者您的使用被可适用的例外或限制所允许。</li>' +
                                    '<li>不提供担保。许可协议可能不会给与您意图使用的所必须的所有许可。例如，其他权利比如形象权、隐私权或人格权可能限制您如何使用作品。</li>' +
                                '</ul>' +
                                '<br/>' +
                                "<h2>许可证全文</h2>" +
                                '通过行使本协议所授予的权利（定义如下），您接受并同意受到知识共享（Creative Commons）署名—非商业性使用—禁止演义4.0国际公共许可协议（以下简称“本公共许可协议”）的约束。从合同解释的角度来看，您获得授权的对价是接受本协议的条款，许可人授予您这些权利的对价是可以通过采用本协议条款发布授权作品（material）而获得利益。' +
                                '<h4>第一条定义</h4>' +
                                '<ol>' +
                                    '<li><strong>演绎作品（Adapted Material）：</strong>指受到著作权与类似权利保护的，基于授权作品（Licensed Material）而创作的作品（material），例如对授权作品（Licensed Material）的翻译、改编、编排、改写或其他依据著作权与类似权利需要获得所有人许可的修改。为本公共许可协议之目的，当授权作品（Licensed Material）为音乐作品、表演或录音时，将其依时间序列关系与动态影像配合一致而形成的作品，视为演绎作品（Adapted Material）。</li>' +
                                    '<li><strong>著作权与类似权利：</strong>指著作权和/或与著作权紧密联系的类似权利。类似权利包括但不限于：表演者权、广播组织权、录音录像制作者权、以及数据库特别权利，而不论上述权利的定义和归类如何。为本公共许可协议之目的，第二条b款第（1）项与第（2）项所列权利不属于著作权与类似权利。</li>' +
                                    '<li><strong>有效的技术措施：</strong>指根据各司法管辖区遵循《世界知识产权组织版权条约》（1996年12月20日通过）第十一条或类似国际协定项下的义务所制定的法律，在没有适当的授权的情况下，禁止使用者规避的技术措施。</li>' +
                                    '<li><strong>例外与限制：</strong>指合理使用（Fair Dealing and Fair Use）和/或其他适用于您对授权作品（Licensed Material）的使用的著作权与类似权利的例外或限制。</li>' +
                                    '<li><strong>授权作品（Licensed Material）：</strong>指许可人通过本公共许可协议授权的文学、艺术作品（artistic or literary work），数据库或其他作品（material）。</li>' +
                                    '<li><strong>协议所授予的权利：</strong>指依据本公共许可协议的条款和条件所授予您的各项权利，限于适用于您对授权作品（Licensed Material）的使用且许可人有权许可的著作权与类似权利。</li>' +
                                    '<li><strong>许可人：</strong>指通过本公共许可协议进行授权的个人或组织。</li>' +
                                    '<li><strong>非商业性使用：</strong>指该使用的主要意图或者指向并非获取商业优势或金钱报酬。为本公共许可协议之目的，以数字文件共享或类似方式，用授权作品（Licensed Material）交换其他受到著作权与类似权利保护的作品（material）是非商业性使用，只要该交换不涉及金钱报酬的支付。</li>' +
                                    '<li><strong>分享：</strong>指以需要“协议所授予的权利”许可的任何方法或程序向公众提供作品（material），包括复制、公共展示、公开表演、发行、散布、传播、进口或提供作品（material）给公众以便其能在其选定的时间和地点接收作品（material）。</li>' +
                                    '<li><strong>数据库特别权利：</strong>指除了著作权之外，衍生于1996年3月11日通过的《欧洲议会与欧盟理事会关于数据库法律保护的指令》（Directive 96/9/EC）及其修改或后续版本的权利，或其他国家或地区本质上与之等同的权利。</li>' +
                                    '<li><strong>您：</strong>指依据本公共许可协议行使其所获得授予之权利的个人或机构。<strong>“您的”</strong>有相应的含义。</li>' +
                                '</ol>' +
                                '<h4>第二条授权范围</h4>' +
                                '<ol>' +
                                    '<li><strong>授权</strong><ol>' +
                                        '<li>根据本公共许可协议的条款，许可人授予您在全球范围内，免费的、不可再许可、非独占、不可撤销的许可，以对授权作品（Licensed Material）行使以下“协议所授予的权利”：<ol>' +
                                            '<li>复制和分享授权作品（Licensed Material）的全部或部分，仅限于非商业性使用；以及</li>' +
                                            '<li>为非商业目的创作、复制演绎作品（Adapted Material），但不得分享该演绎作品。</li>' +
                                        '</ol></li>' +
                                        '<li><u>例外和限制</u>为避免疑义，若著作权的例外和限制适用于您对授权作品（Licensed Material）的使用，本公共许可协议将不适用，您也无须遵守本公共许可协议之条款。</li>' +
                                        '<li><u>期限</u>本公共许可协议的期限规定于第六条a款。</li>' +
                                        '<li><u>媒介和形式；允许的技术修改</u>许可人授权您在任何媒介以任何形式（不论目前已知的或未来出现的）行使本协议授予的权利，并为之进行必要的技术修改。许可人放弃和/或同意不主张任何权利以阻止您为了行使协议项下权利进行必要的技术修改，包括为规避有效技术措施所必须的技术修改。为了本公共许可协议之目的，基于本协议第二条a款第（4）项进行的技术修改不构成演绎作品（Adapted Material）。</li>' +
                                        '<li><u>后续接受者</u>。<ol>' +
                                            '<li><u>来自许可人的要约——授权作品（Licensed Material）</u>本授权作品（Licensed Material）的每一个后续接受者都自动取得许可人的要约，以按照本公共许可协议的条款行使协议授予的权利。</li>' +
                                            '<li><u>禁止下游限制</u>若会限制授权作品（Licensed Material）后续接受者行使本协议所授予的权利，则您不得对授权作品（Licensed Material）提出或增加任何额外的或不同的条款，或使用任何有效技术措施。</li>' +
                                        '</ol></li>' +
                                        '<li><u>并非背书</u>本公共许可协议不构成、或不得被解释为允许您声明或主张：您或您对授权作品（Licensed Material）的使用与许可人（或第三条a款第（1）项（A）目（i）所规定要求提供署名的权利人）相关联，或得到其赞助、同意或被授予正式地位。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>其他权利</strong><ol>' +
                                        '<li>依据本公共许可协议，著作人身权，例如保护作品完整权、形象权、隐私权或其他类似的人格权利，不在许可范围内。但是，在条件允许的情况下，许可人可以在必要范围内放弃和/或同意不主张其权利，以便您行使本协议所授予的权利。</li>' +
                                        '<li>本公共许可协议不适用于任何专利权或商标权许可。</li>' +
                                        '<li>在自愿的或可放弃的法定或强制许可机制下，许可人在最大可能范围内放弃对您因行使本协议所授予的权利而产生的使用费的权利，不论是直接收取或通过集体管理组织收取。在其他任何情况下（包括授权作品（Licensed Material）被商业性使用的情形），许可人明确保留收取使用费的任何权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第三条授权条件</h4>' +
                                '您行使被许可的权利明确受以下条件限制：' +
                                '<ol>' +
                                    '<li><strong>署名</strong><ol>' +
                                        '<li><p>若您分享本授权作品（Licensed Material），您必须：</p><ol>' +
                                            '<li>保留如下标识（如果许可人提供授权作品（Licensed Material）的同时提供如下标识）：<ol>' +
                                                '<li>以许可人要求的任何合理方式，标识本授权作品（Licensed Material）创作者和其他被指定署名的人的身份（包括指定的笔名）；</li>' +
                                                '<li>著作权声明；</li>' +
                                                '<li>有关本公共许可协议的声明；</li>' +
                                                '<li>有关免责的声明；</li>' +
                                                '<li>在合理可行情况下，本授权作品（Licensed Material）的网址（URI）或超链接；</li>' +
                                            '</ol></li>' +
                                            '<li>表明您是否修改本授权作品（Licensed Material）及保留任何先前修改的标记；及</li>' +
                                            '<li>表明授权作品（Licensed Material）依据本公共许可协议授权，并提供本公共许可协议全文，或者本公共许可协议的网址（URI）或超链接。</li>' +
                                        '</ol>为避免疑义，依据本公共许可协议，您没有被授予分享演绎作品（Adapted Material）的许可。</li>' +
                                        '<li>依据您分享本授权作品（Licensed Material）的媒介、方法及情況，您可以采用任何合理方式满足第三条a款第（1）项的条件。例如，提供包含所要求信息来源的网址（URI）或超链接可算是合理地满足此处的条件。</li>' +
                                        '<li>如果许可人要求，您必须在合理可行的范围内移除第三条a款第（1）项（A）目所要求的任何信息。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第四条数据库特别权利</h4>' +
                                '当协议所授予的权利包含数据库特别权利，而该数据库特别权利适用于您对授权作品（Licensed Material）的使用时：' +
                                '<ol>' +
                                    '<li>为避免疑义，第二条a款第（1）项授权您，摘录、再利用、复制和分享全部或绝大部分数据库资料，但仅限于非商业性目的且不得分享演绎作品；</li>' +
                                    '<li>如果您将数据库资料的全部或绝大部分纳入您享有数据库特别权利的另一数据库，则您享有数据库特别权利的该数据库（而非其中的单个内容）视为演绎作品（Adapted Material）；</li>' +
                                    '<li>如果您分享全部或大部分该数据库的资料，您必须遵守第三条a款规定的条件。</li>' +
                                '</ol>' +
                                '为避免疑义，当协议所授予的权利包含其他著作权与类似权利时，第四条补充且不取代本公共许可协议所规定的您的义务。' +
                                '<h4>第五条免责声明及责任限制条款</h4>' +
                                '<ol>' +
                                    '<li><strong>除非许可人另有保证，否则在最大可能范围内，许可人按其现状和现有之基础提供授权作品（Licensed Material），且没有就授权作品（Licensed Material）做出任何形式的陈述或保证：无论明示、默示、法定或其他形式，包括但不限于任何有关本授权作品（Licensed Material）的权属保证、可交易性、适于特定目的、未侵害他人权利、没有潜在或其他瑕疵、精确性或是否有错误，不管是否已知或可发现。当免责声明全部或部分不被允许时，此免责声明可能不适用于您。</strong></li>' +
                                    '<li><strong>在最大可能范围内，对于任何因本公共许可协议或使用授权作品（Licensed Material）引起的直接的、特殊的、间接的、附随的、连带的、惩罚性的、警告性的，或其他的损失、成本、费用或损害，许可人不对您负任何法律上或其他的责任（包括但不限于过失责任）。当责任限制部分或全部不被允许时，该限制不适用于您。</strong></li>' +
                                '</ol>' +
                                '<ol start="3">' +
                                    '<li>前述免责及责任限制声明，应尽可能以最接近于完全排除全部责任的方式解释。</li>' +
                                '</ol>' +
                                '<h4>第六条期限与终止</h4>' +
                                '<ol>' +
                                    '<li>本公共许可协议在著作权与类似权利存续期间内有效。然而，如果您没有遵守此公共许可协议，则您依据此公共许可协议享有的权利自动终止。</li>' +
                                    '<li>当您使用本授权作品（Licensed Material）的权利根据第六条a款终止时，您的权利在下述情况下恢复：<ol>' +
                                        '<li>自违反协议的行为纠正之日起自动恢复，但须在您发现违反情形后30日内纠正；或</li>' +
                                        '<li>根据许可人明示恢复权利的意思表达。</li>' +
                                    '</ol>为避免疑义，本公共许可协议第六条b款不影响许可人就您违反本公共许可协议的行为寻求法律救济。</li>' +
                                    '<li>为避免疑义，许可人也可在任何时间，以另外的条款或条件提供本授权作品（Licensed Material），或者停止传播本授权作品（Licensed Material）；然而，许可人此种行为不会终止本公共许可协议。</li>' +
                                    '<li>本协议第一、五、六、七及第八条，不因本公共许可协议终止而失效。</li>' +
                                '</ol>' +
                                '<h4>第七条其他条款和条件</h4>' +
                                '<ol>' +
                                    '<li>除非明示同意，否则许可人不受您表达的任何附加或不同条款或条件约束。</li>' +
                                    '<li>本公共许可协议未提及的关于授权作品（Licensed Material）之任何安排、共识或协议，不属于且独立于本公共许可协议的条款及条件。</li>' +
                                '</ol>' +
                                '<h4>第八条解释</h4>' +
                                '<ol>' +
                                    '<li>为避免疑义，本许可协议不会也不应被解释为减少、限制、约束或施加条件于无需本公共许可协议授权即可依法行使的对授权作品（Licensed Material）的任何使用。</li>' +
                                    '<li>在最大可能范围内，如果本公共许可协议的任何条款被视为无法执行，该条款在必要的最小限度内，自动调整至可以执行。如果该条款不能被调整，其应自本公共许可协议中排除适用，不影响其余条款的效力。</li>' +
                                    '<li>除许可人明示同意，本公共许可协议的任何条款或条件均不得放弃。</li>' +
                                    '<li>本公共许可协议条款不构成、也不得被解释为限制或者放弃适用于许可人或您的特权或豁免，包括豁免于任何司法管辖区或行政机构的法律程序。</li>' +
                                '</ol>',
                        },
                        CCBYNCSA: {
                            name: "Несвободная (CC-BY-NC-SA)",
                            text: "<h1>知识共享（Creative Commons）署名—非商业性使用—相同方式共享 4.0公共许可协议国际版</h1>" +
                                "<h2>概括</h2>" +
                                "<i><b>免责声明。</b> Краткое описание (Commons Deed) не является юридическим инструментом. Это простое описание лицензии, написанное доступным языком изложение некоторых основных её положений. Представьте его как дружественный интерфейс к тексту лицензии. Сам по себе этот документ не обладает правовой силой, его содержимое не входит в состав лицензии.</i>" +
                                "<br/><br/>" +
                                '<h4>您可以自由地：</h4>' +
                                '<ul>' +
                                    '<li><strong>共享</strong>—在任何媒介以任何形式复制、发行本作品</li>' +
                                    '<li><strong>演绎</strong>—修改、转换或以本作品为基础进行创作</li>' +
                                '</ul>' +
                                '<ul>' +
                                    '<li>只要你遵守许可协议条款，许可人就无法收回你的这些权利。</li>' +
                                '</ul>' +
                                '<h4>惟须遵守下列条件：</h4>' +
                                '<ul>' +
                                    '<li><strong>署名</strong>—您必须给出适当的署名，提供指向本许可协议的链接，同时标明是否（对原始作品）作了修改。您可以用任何合理的方式来署名，但是不得以任何方式暗示许可人为您或您的使用背书。</li>' +
                                    '<li><strong>非商业性使用</strong>—您不得将本作品用于商业目的。</li>' +
                                    '<li><strong>相同方式共享</strong>—如果您再混合、转换或者基于本作品进行创作，您必须基于与原先许可协议相同的许可协议分发您贡献的作品。</li>' +
                                    '<li><strong>没有附加限制</strong>—您不得适用法律术语或者技术措施从而限制其他人做许可协议允许的事情。</li>' +
                                '</ul>' +
                                '<h4>声明：</h4>' +
                                '<ul>' +
                                    '<li>您不必因为公共领域的作品要素而遵守许可协议，或者您的使用被可适用的例外或限制所允许。</li>' +
                                    '<li>不提供担保。许可协议可能不会给与您意图使用的所必须的所有许可。例如，其他权利比如形象权、隐私权或人格权可能限制您如何使用作品。</li>' +
                                '</ul>' +
                                '<br/>' +
                                "<h2>许可证全文</h2>" +
                                '通过行使本协议所授予的权利（定义如下），您接受并同意受到知识共享（Creative Commons）署名—非商业性使用—相同方式共享4.0国际公共许可协议（以下简称“本公共许可协议”）的约束。从合同解释的角度来看，您获得授权的对价是接受本协议的条款，许可人授予您这些权利的对价是可以通过采用本协议条款发布授权作品（material）而获得利益。' +
                                '<h4>第一条定义</h4>' +
                                '<ol>' +
                                    '<li><strong>演绎作品（Adapted Material）：</strong>指受到著作权与类似权利保护的，基于授权作品（Licensed Material）而创作的作品（material），例如对授权作品（Licensed Material）的翻译、改编、编排、改写或其他依据著作权与类似权利需要获得所有人许可的修改。为本公共许可协议之目的，当授权作品（Licensed Material）为音乐作品、表演或录音时，将其依时间序列关系与动态影像配合一致而形成的作品，视为演绎作品（Adapted Material）。</li>' +
                                    '<li><strong>演绎作者的许可：</strong>指您依据本公共许可协议对在演绎作品（Adapted Material）中自己所贡献的部分所享有的著作权与类似权利进行授权的协议。</li>' +
                                    '<li><strong>署名—非商业性使用—相同方式共享兼容协议：</strong>指在<a href="//creativecommons.org/compatiblelicenses"> creativecommons.org/compatiblelicenses</a>上列出且经知识共享组织（Creative Commons）认可、实质上与本公共许可协议相当的协议。</li>' +
                                    '<li><strong>著作权与类似权利：</strong>指著作权和/或与著作权紧密联系的类似权利。类似权利包括但不限于：表演者权、广播组织权、录音录像制作者权、以及数据库特别权利，而不论上述权利的定义和归类如何。为本公共许可协议之目的，第二条b款第（1）项与第（2）项所列权利不属于著作权与类似权利。</li>' +
                                    '<li><strong>有效的技术措施：</strong>指根据各司法管辖区遵循《世界知识产权组织版权条约》（1996年12月20日通过）第十一条或类似国际协定项下的义务所制定的法律，在没有适当的授权的情况下，禁止使用者规避的技术措施。</li>' +
                                    '<li><strong>例外与限制：</strong>指合理使用（Fair Dealing and Fair Use）和/或其他适用于您对授权作品（Licensed Material）的使用的著作权与类似权利的例外或限制。</li>' +
                                    '<li><strong>授权要素：</strong>指知识共享公共许可协议（CCPL）名称中所包含的协议特征。本公共许可协议的授权要素包括：署名、非商业性使用和相同方式共享。</li>' +
                                    '<li><strong>授权作品（Licensed Material）：</strong>指许可人通过本公共许可协议授权的文学、艺术作品（artistic or literary work），数据库或其他作品（material）。</li>' +
                                    '<li><strong>协议所授予的权利：</strong>指依据本公共许可协议的条款和条件所授予您的各项权利，限于适用于您对授权作品（Licensed Material）的使用且许可人有权许可的著作权与类似权利。</li>' +
                                    '<li><strong>许可人：</strong>指通过本公共许可协议进行授权的个人或组织。</li>' +
                                    '<li><strong>非商业性使用：</strong>指该使用的主要意图或者指向并非获取商业优势或金钱报酬。为本公共许可协议之目的，以数字文件共享或类似方式，用授权作品（Licensed Material）交换其他受到著作权与类似权利保护的作品（material）是非商业性使用，只要该交换不涉及金钱报酬的支付。</li>' +
                                    '<li><strong>分享：</strong>指以需要“协议所授予的权利”许可的任何方法或程序向公众提供作品（material），包括复制、公共展示、公开表演、发行、散布、传播、进口或提供作品（material）给公众以便其能在其选定的时间和地点接收作品（material）。</li>' +
                                    '<li><strong>数据库特别权利：</strong>指除了著作权之外，衍生于1996年3月11日通过的《欧洲议会与欧盟理事会关于数据库法律保护的指令》（Directive 96/9/EC）及其修改或后续版本的权利，或其他国家或地区本质上与之等同的权利。</li>' +
                                    '<li><strong>您：</strong>指依据本公共许可协议行使其所获得授予之权利的个人或机构。<strong>“您的”</strong>有相应的含义。</li>' +
                                '</ol>' +
                                '<h4>第二条授权范围</h4>' +
                                '<ol>' +
                                    '<li><strong>授权</strong><ol>' +
                                        '<li>根据本公共许可协议的条款，许可人授予您在全球范围内，免费的、不可再许可、非独占、不可撤销的许可，以对授权作品（Licensed Material）行使以下“协议所授予的权利”：<ol>' +
                                            '<li>复制和分享授权作品（Licensed Material）的全部或部分，仅限于非商业性使用；以及</li>' +
                                            '<li>为非商业目的创作、复制和分享演绎作品（Adapted Material）。</li>' +
                                        '</ol></li>' +
                                        '<li><u>例外和限制</u>为避免疑义，若著作权的例外和限制适用于您对授权作品（Licensed Material）的使用，本公共许可协议将不适用，您也无须遵守本公共许可协议之条款。</li>' +
                                        '<li><u>期限</u>本公共许可协议的期限规定于第六条 a 款。</li>' +
                                        '<li><u>媒介和形式；允许的技术修改</u>许可人授权您在任何媒介以任何形式（不论目前已知的或未来出现的）行使本协议授予的权利，并为之进行必要的技术修改。许可人放弃和/或同意不主张任何权利以阻止您为了行使协议项下权利进行必要的技术修改，包括为规避有效技术措施所必须的技术修改。为了本公共许可协议之目的，基于第二条a款第（4）项进行的技术修改不构成演绎作品（Adapted Material）。</li>' +
                                        '<li><u>后续接受者</u><ol>' +
                                            '<li><u>来自许可人的要约——授权作品（Licensed Material）</u>本授权作品（Licensed Material）的每一个后续接受者都自动取得许可人的要约，以按照本公共许可协议的条款行使协议授予的权利。</li>' +
                                            '<li><u>来自许可人的额外要约——演绎作品（Adapted Material）</u>您基于授权作品（Licensed Material）创作的演绎作品（Adapted Material）的每一个后续接受者都自动取得许可人的要约，以按照您所适用的“演绎作者的许可”协议的条款行使协议所授予的权利。</li>' +
                                            '<li><u>禁止下游限制</u>若会限制授权作品（Licensed Material）后续接受者行使本协议所授予的权利，则您不得对授权作品（Licensed Material）提出或增加任何额外的或不同的条款，或使用任何有效技术措施。</li>' +
                                        '</ol></li>' +
                                        '<li><u>并非背书</u>本公共许可协议不构成、或不得被解释为允许您声明或主张：您或您对授权作品（Licensed Material）的使用与许可人或第三条a款第（1）项（A）目（i）所规定要求提供署名的权利人相关联，或得到其赞助、同意或被授予正式地位。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>其他权利</strong><ol>' +
                                        '<li>依据本公共许可协议，著作人身权，例如保护作品完整权、形象权、隐私权或其他类似的人格权利，不在许可范围内。但是，在条件允许的情况下，许可人可以在必要范围内放弃和/或同意不主张其权利，以便您行使本协议所授予的权利。</li>' +
                                        '<li>本公共许可协议不适用于任何专利权或商标权许可。</li>' +
                                        '<li>在自愿的或可放弃的法定或强制许可机制下，许可人在最大可能范围内放弃对您因行使本协议所授予的权利而产生的使用费的权利，不论是直接收取或通过集体管理组织收取。在其他任何情况下（包括授权作品（Licensed Material）被商业性使用的情形），许可人明确保留收取使用费的任何权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第三条授权条件</h4>' +
                                '<p>您行使被许可的权利明确受以下条件限制：</p>' +
                                '<ol>' +
                                    '<li><strong>署名</strong><ol>' +
                                        '<li>若您分享本授权作品（Licensed Material）（包含修改格式），您必须：<ol>' +
                                            '<li>保留如下标识（如果许可人提供授权作品（Licensed Material）的同时提供如下标识）：<ol>' +
                                                '<li>以许可人要求的任何合理方式，标识本授权作品（Licensed Material）创作者和其他被指定署名的人的身份（包括指定的笔名）；</li>' +
                                                '<li>著作权声明；</li>' +
                                                '<li>有关本公共许可协议的声明；</li>' +
                                                '<li>有关免责的声明；</li>' +
                                                '<li>在合理可行情况下，本授权作品（Licensed Material）的网址（URI）或超链接；</li>' +
                                            '</ol></li>' +
                                            '<li>表明您是否修改本授权作品（Licensed Material）及保留任何先前修改的标记；及</li>' +
                                            '<li>表明授权作品（Licensed Material）依据本公共许可协议授权，并提供本公共许可协议全文，或者本公共许可协议的网址（URI）或超链接。</li>' +
                                        '</ol></li>' +
                                        '<li>依据您分享本授权作品（Licensed Material）的媒介、方法及情況，您可以采用任何合理方式满足第三条a款第（1）项的条件。例如，提供包含所要求信息来源的网址（URI）或超链接可算是合理地满足此处的条件。</li>' +
                                        '<li>如果许可人要求，您必须在合理可行的范围内移除第三条a款第（1）项（A）目所要求的任何信息。</li>' +
                                    '</ol></li>' +
                                    '<li><strong>相同方式共享</strong>除第三条a款的条件外，如果您分享您创作的演绎作品（Adapted Material），则下列条件也适用：<ol>' +
                                        '<li>您适用的“演绎作者的许可”协议必须是与本许可协议具有相同授权要素的知识共享（Creative Commons）许可协议（可以是本版本或后续版本），或者其他与“署名－非商业性使用－相同方式共享”协议兼容的许可协议。</li>' +
                                        '<li>您必须提供您适用的“演绎作者的许可”协议全文或者该许可协议的网址（URI）或超链接。依据您分享您的演绎作品（Adapted Material）所使用的媒介、方法及情況，您可以采用任何合理方式满足此条件。</li>' +
                                        '<li>您不得提出或施加任何附加或不同的条款或条件、或在演绎作品（Adapted Material）上应用任何有效的技术措施，以限制使用者行使依您所适用的“演绎作者的许可”协议所授予的权利。</li>' +
                                    '</ol></li>' +
                                '</ol>' +
                                '<h4>第四条数据库特别权利</h4>' +
                                '当协议所授予的权利包含数据库特别权利，而该数据库特别权利适用于您对授权作品（Licensed Material）的使用时：' +
                                '<ol>' +
                                    '<li>为避免疑义，第二条a款第（1）项授权您，仅限于以非商业性目的，摘录、再利用、复制和分享全部或绝大部分数据库资料；</li>' +
                                    '<li>如果您将数据库资料的全部或绝大部分纳入您享有数据库特别权利的另一数据库，则您享有数据库特别权利的该数据库（而非其中的单个内容）视为演绎作品（Adapted Material），适用第三条b款的要求；</li>' +
                                    '<li>如果您分享全部或大部分该数据库的资料，您必须遵守第三条a款规定的条件。</li>' +
                                '</ol>' +
                                '为避免疑义，当协议所授予的权利包含其他著作权与类似权利时，第四条补充且不取代本公共许可协议所规定的您的义务。' +
                                '<h4>第五条免责声明及责任限制条款</h4>' +
                                '<ol>' +
                                    '<li><strong>除非许可人另有保证，否则在最大可能范围内，许可人按其现状和现有之基础提供授权作品（Licensed Material），且没有就授权作品（Licensed Material）做出任何形式的陈述或保证：无论明示、默示、法定或其他形式，包括但不限于任何有关本授权作品（Licensed Material）的权属保证、可交易性、适于特定目的、未侵害他人权利、没有潜在或其他瑕疵、精确性或是否有错误，不管是否已知或可发现。当免责声明全部或部分不被允许时，此免责声明可能不适用于您。</strong></li>' +
                                    '<li><strong>在最大可能范围内，对于任何因本公共许可协议或使用授权作品（Licensed Material）引起的直接的、特殊的、间接的、附随的、连带的、惩罚性的、警告性的，或其他的损失、成本、费用或损害，许可人不对您负任何法律上或其他的责任（包括但不限于过失责任）。当责任限制部分或全部不被允许时，该限制不适用于您。</strong></li>' +
                                '</ol>' +
                                '<ol start="3">' +
                                    '<li>前述免责及责任限制声明，应尽可能以最接近于完全排除全部责任的方式解释。</li>' +
                                '</ol>' +
                                '<h4>第六条期限与终止</h4>' +
                                '<ol>' +
                                    '<li>本公共许可协议在著作权与类似权利存续期间内有效。然而，如果您没有遵守此公共许可协议，则您依据此公共许可协议享有的权利自动终止。</li>' +
                                    '<li>当您使用本授权作品（Licensed Material）的权利根据第六条a款终止时，您的权利在下述情况下恢复：<ol>' +
                                        '<li>自违反协议的行为纠正之日起自动恢复，但须在您发现违反情形后30日内纠正；或</li>' +
                                        '<li>根据许可人明示恢复权利的意思表达。</li>' +
                                    '</ol>为避免疑义，本公共许可协议第六条b款不影响许可人就您违反本公共许可协议的行为寻求法律救济。</li>' +
                                    '<li>为避免疑义，许可人也可在任何时间，以另外的条款或条件提供本授权作品（Licensed Material），或者停止传播本授权作品（Licensed Material）；然而，许可人此种行为不会终止本公共许可协议。</li>' +
                                    '<li>本协议第一、五、六、七及第八条，不因本公共许可协议终止而失效。</li>' +
                                '</ol>' +
                                '<h4>第七条其他条款和条件</h4>' +
                                '<ol>' +
                                    '<li>除非明示同意，否则许可人不受您表达的任何附加或不同条款或条件约束。</li>' +
                                    '<li>本公共许可协议未提及的关于授权作品（Licensed Material）之任何安排、共识或协议，不属于且独立于本公共许可协议的条款及条件。</li>' +
                                '</ol>' +
                                '<h4>第八条解释</h4>' +
                                '<ol>' +
                                    '<li>为避免疑义，本许可协议不会也不应被解释为减少、限制、约束或施加条件于无需本公共许可协议授权即可依法行使的对授权作品（Licensed Material）的任何使用。</li>' +
                                    '<li>在最大可能范围内，如果本公共许可协议的任何条款被视为无法执行，该条款在必要的最小限度内，自动调整至可以执行。如果该条款不能被调整，其应自本公共许可协议中排除适用，不影响其余条款的效力。</li>' +
                                    '<li>除非许可人明示同意，本公共许可协议的任何条款或条件均不得放弃。</li>' +
                                    '<li>本公共许可协议条款不构成、也不得被解释为限制或者放弃适用于许可人或您的特权或豁免，包括豁免于任何司法管辖区或行政机构的法律程序。</li>' +
                                '</ol>',
                        },
                    },
                    softwareLicensesTitle: "Лицензии на инструменты",
                    software: {
                        nonFree: {
                            name: "Проприетарная",
                            text: "",
                        },
                        GnuGPL: {
                            name: "Свободная (GnuGPL)",
                            text: "",
                        },
                    },
                },
                moreAboutLicenses: "更多关于许可证的信息",
                price: "价格，卢布",
                updatePrice: "更新价格，卢布",
                cardPhoto: "卡片照片",
                cardPhotoDefault: "默认",
                cardPhotoChooseIconsToView: "选择要显示的图标",
                uploadOrDropFileHere: "在此处上传或拖放文件",
                publicity: "公开性",
                anybody: "任何人都可以查看",
                restricted: "只有特定用户可以查看",
                updatingScope: "更新范围",
                majorScope: "重大更新",
                minorScope: "次要更新",
                patchScope: "错误修复或小改进",
                updatingReview: "更新审查",
                publish: "发布",
                publicationWarning: "点击按钮即表示您同意",
                publicationRules: "发布规则",
                publicationWarningUnion: "和",
                publicationLicense: "所选许可证的条款",
                warnings: {
                    maxFileNumber: "最大文件数:",
                },
                hint: "显示前六个图标",
                validation: {
                    stringTooLong: "*超过允许的最大字符数 —",
                    stringTooShort: "*字符数必须大于两个",
                    nameRequired: "*请提供产品名称",
                    categoryRequired: "*选择产品类型",
                    cardPhotoTypeRequired: "*选择产品图标生成类型",
                    cardPhotoShouldBeImage: "*产品照片必须是图片",
                    publicityTypeRequired: "*选择产品可访问性级别",
                    tagsRequired: "*选择一个或多个标签",
                    tagWrong: ": 包含逗号",
                    fileRequired: "*必须添加产品文件",
                    fileCorrupted: "文件未上传，因为它已损坏！",
                    archive: {
                        unsupportedArchiveType: "不支持此文件格式",
                        archiveIsEmpty: "存档为空",
                        archiveContainsIncorrectFiles: "存档中的以下文件不正确，因此未加载：",
                        allFilesInArchiveAreIncorrect: "存档中的所有文件都不正确",
                        archiveIsIncorrect: "存档文件不正确"
                    },
                    cardPhotoFromSelectedItemsRequired: "*您必须从存档中选择一到六个图标来形成产品图像",
                    maximumFileSize: "*允许的最大文件大小:",
                    Mb: "姆布",
                    unsupportedFileFormat: "*接受的文件格式:",
                    licenseTypeRequired: "*选择许可证类型",
                    priceRequired: "*指定价格",
                    priceBelowZero: "*价格不能为负数",
                    priceTooHigh: "*超过允许的最大值",
                    updateScopeRequired: "*表明产品发生了多少变化"
                },
            },
            page: {
                buy: "买",
                youAreAuthor: "你是该产品的作者",
                youAreCoauthor: "您是该产品的共同作者",
                productPurchased: "该产品已被购买",
                addToFavourites: "添加到收藏夹",
                removeFromFavourites: "从收藏夹中删除",
                price: "价格",
                updatePrice: "更新价格",
                usingConditions: "使用条件",
                author: "作者",
                authorDoesntExist: "匿名作者",
                version: "版本",
                coauthors: "合作者",
                rating: "评分",
                category: "类别",
                userCount: "用户数量",
                tags: "标签",
                createdAt: "创建时间",
                description: "描述",
                feedbacks: "反馈",
                preview: "预览",
                versions: "版本",
                goToVersionPage: "转到产品版本页面",
                leaveYourFeedback: "留下您的反馈",
                sendFeedback: "发送",
                showMoreComments: "显示更多评论",
                commentSent: "已添加评论",
                expandComment: "展开",
                foldComment: "折叠",
            },
        },
        account: {
            myProfile: "我的个人资料",
            editProfile: "编辑个人资料",
            deleteProfile: "删除个人资料",
            addProduct: "添加产品",
            editIconArrangement: "编辑图标排列",
            editIconArrangementDev: "编辑图标排列（开发者版）",
            editIconParametersDev: "编辑图标参数（开发者版）",
            updateIcons: "更新图标配置",
            becomeAnAuthor: "成为作者",
            creativeMapsStudio: "创意地图工作室",
            purchasedProducts: "已购买的产品",
            publishedProducts: "已发布的产品",
            unpublishedProducts: "未发布的产品",
            favouriteProducts: "收藏的产品",
        },
        icons: {
            header: "图标排列",
            saveChanges: "保存更改",
            cancelChanges: "取消更改",
            exitWarn: "您确定要离开页面吗？未保存的更改将丢失！",
            goOut: "退出编辑",
            polygons: "多边形",
            polylines: "折线",
            icons: "图标",
            checkAll: "全选",
            uncheckAll: "取消全选",
            info: "在这里，您可以为Creative Maps Studio创建图标排列。只需选择并拖放图标到相应的分区，并保存更改。图标排列将自动加载到Creative Maps Studio中。您还可以创建和调整新的图标排列和分区。",
            partitionHint: "拖放以添加",
            partitionDropHint: "拖放对象以将其添加到分区",
            subpartitionDropHint: "拖放对象以将其添加到子分区",
            partitionIconDeleteHint: "拖放到图标面板以删除",
            partitionSimplifyWarn: "您确定要取消子分区吗？所有图标将被放在一起",
            partitionComplicateWarn: "您确定要添加子分区吗？所有图标将被移动到第一个子分区",
            tabDeleteWarn: "您确定要删除此排列吗？无法恢复！",
            partitionDeleteWarn: "您确定要删除此分区吗？无法恢复！",
            subpartitionDeleteWarn: "您确定要删除此子分区吗？无法恢复！",
            newTab: "新排列",
            addPartition: "添加对象分区",
            newPartition: "新分区",
            addSubpartition: "添加子分区",
            newSubpartition: "新子分区",
            iconSetSaved: "图标排列已成功保存",
        },
        payment: {
            order: "订单",
            name: "名称",
            cost: "价格",
            cardNumber: "卡号",
            cardHolderName: "持卡人姓名",
            validity: "有效期限",
            pay: "支付",
            goBack: "返回",
            validation: {
                shouldNumber: "字段只能包含数字",
                shouldString: "字段内容必须是字符串",
                required: "必填",
                validity: "字段应为'MM/DD'格式",
            },
        },
        page404: {
            title: "页面不可用",
            explanation: "可能是页面已被删除或移动，或者您没有足够的权限查看该页面",
            goToHome: "返回主页",
        },
        loadingPage: {
            title: "加载中..."
        },
        productPublicationCompleted: {
            title: "恭喜，您的作品已发布！",
            explanation: "已发布的作品在个人账户中可供访问",
            goToHome: "进入个人账户",
        },
        productPurchased: {
            title: "恭喜，您购买了新产品！",
            explanation: "购买的产品在个人账户中可供访问",
            goToHome: "进入个人账户",
            goToMainPage: "继续购物",
        },
        verified: {
            title: "恭喜，您的账户已验证！",
            goToStudio: "进入 Creative Maps Studio",
            goToStore: "进入 Creative Maps Store"
        },
    },
    project: {
        myProjects: {
            myProjects: "我的项目",
            iAmAnAuthor: "我是作者",
            iAmAnInvitedAnalyst: "我是受邀分析师",
            iAmAParticipant: "我是参与者",
            createNewProject: "创建新项目",
            goToStudio: "进入工作室",
            goToStore: "进入商店",
        },
        certainProject: {
            certainProject: "当前项目: ",
            goToAnalytics: "进入分析",
            analysts: "分析师: ",
            participants: "参与者: ",
            maps: "地图: ",
            surveys: "调查: ",
            finish: "完成",
            resume: "继续",
            edit: "编辑",
            add: "添加分析师",
            projectEditing: "项目编辑",
            delete: "删除项目",
            returnToList: "返回项目列表",
            returnToView: "返回项目主页面",
        },
        newProject: {
            newProject: "创建项目"
        },
        analytics: {
            returnToStudio: "← 工作室",
            mainPage: {
                mainPage: "主页",
                convertersProcessorsAndMetrics: "转换器，处理器和指标",
                projectMaps: "项目地图",
                mvExplanation: "(MV - 地图版本)",
                showLastUserMapOnly: "仅显示最后一个用户的地图",
                ex: "从",
                in: "到",
                cludeFollowingUsers: "包括以下用户:",
                update: "更新",
                mapCount: "地图数量: ",
                dfd: {
                    uploading: "正在更新:",
                    sending: "发送中...",
                    doYouWantToSaveTheResults: "是否要保存结果？",
                    errorOccurred: "发生错误",
                    back: "返回",
                    converters: "转换器",
                    visualizers: "可视化工具",
                    run: "运行",
                    reset: "重置",
                    configurator: {
                        title: "上传要处理的数据",
                        useServerData: "使用服务器数据",
                        useLocalData: "使用本地数据",
                        loadedDataType: "加载数据类型:",
                        useMaps: "使用地图",
                        useDumpOf: "使用dump...",
                        useAllMapsFromTable: "使用表中的所有地图",
                        useFollowingMaps: "使用以下地图：",
                        chooseTable: "选择表格",
                    },
                    wfpmVisualizer: {
                       maxFrequency: "最大频率:",
                        showDispersion: "显示坐标离散度",
                        translucentRare: "将稀有的对象设置为半透明",
                        showCertainObjects: "显示特定频率的对象",
                        above: "高于",
                        below: "低于",
                        apply: "应用",
                        all: "全部",
                    },
                },
            },
            additionalPage: {
                additionalPage: "其他",
                downloadMapsAndLinks: "下载地图和链接",
                archiveName: "存档名称",
                fileName: "文件名称",
                loadAllMaps: "下载所有地图",
                loadMaps: "下载地图",
                loadLinks: "下载链接",
                startUserId: "第一个用户的ID",
                finishUserId: "最后一个用户的ID",
                shareTokenParser: "分享令牌解析器",
                enterLinkOrToken: "在下面的字段中输入地图链接或令牌",
                send: "发送",
            },
        },
        dfd: {
            nodeTypeHint: {
                "m": "(地图)源地图",
                "mt": "(地图文本) 从地图导出的文本",
                "mlst": "(地图列表符号文本)从地图导出并组合成符号符号格式的文本",
                "wfpm-p": "(WFPM 模式) 未计算的 WFPM 模式（只是为 WFPM 处理的一组映射）",
                "wfpm-pc": "(计算的 WFPM 模式) -- 基于 .wfpm-p 计算的 WFPM 模式",
                "wfpm-pcd": "(WFPM 模式计算差异) 两个计算的 WFPM 格式模式之间的差异",
                "sd": "(调查转储) 有关从数据库导出的所有调查的原始数据",
                "ss": "(Sychev Surveys) 根据 Oleg Sychev 的方案处理调查记录中的数据（调查 json 转换为 csv，技术字段也被删除）",
                "ss-wfpm": "（Sychev 调查丰富了 WFPM）根据 Oleg Sychev 方案进行的调查，丰富了 WFPM 分类结果",
                "ud": "(User Dump) 从数据库导出的原始用户数据",
                "mpng-ssn": "(MaPpiNG 调查定居点名称) 将标准 NP 名称映射到调查中注明的名称（掩码）",
                "mpng-me": "(MaPpiNG 地图平等) 映射地图 id 对的相似性（通过匹配对象 id）",
                "mpng-usn": "(MaPpiNG 用户结算名称) 将标准 NP 名称映射到用户标记的名称",
                "mpng-sn-ru-en": "(将定居点名称 RU 映射到 EN) 将定居点名称从俄语映射到英语",
                "gmit": "(Google 地图信息表) 包含手动填充到 google-docs 的地图信息的文件",
                "gmit-mvk-ss": "(Google 地图信息表，包含邮件、VK id 和 Sychev 调查数据) gmit，其中包含邮件和 VK 用户个人资料及其根据 Oleg Sychev 方案进行的调查的链接",
                "mct": "(Map-China-Texts) 2020-21年学习中国地图时使用的文本导出格式。",
                "mlct": "(Map-List-China-Texts) .mcts 的组合列表以及添加的文件名信息",
                "gm": "(Generalized-Map) 由一组地图构建的广义地图。",
                "fgm": "(广义地图中的频率)广义地图中对象和类别作为整体的频率",
                "mci": "(地图概念信息)地图中概念的名称和坐标",
                "cl": "（概念列表）用于生成默认项目地图的概念名称",
            }
        }
    }
};
